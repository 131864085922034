<template>
  <div class="content">
    <div v-if="isLoading" class="flex flex-col flex-1 gap-5 sm:p-2">
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>
    </div>

    <div v-else class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <!-- <md-button class="md-raised" style="background-color: #141456!important;"><a style="color: white!important;" href="/utilisateurs/admins/nouveau">Nouveau</a></md-button> -->

        <!-- <router-link :to="{ name: 'Utilisateurs / Admins / Nouveau' }"> 
        <md-button class="md-raised" style="background-color: #141456!important;">Nouveau</md-button>
      </router-link> -->

        <md-card>
          <md-card-header
            data-background-color="orange"
          >
            <h4 class="title">Liste des acheteurs à valider</h4>
          </md-card-header>
          <md-card-content>
            <div>
              <md-table style="text-align: center" v-model="buyersToConfirm" table-header-color="orange">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Nom">{{
                    item.user.name
                  }}</md-table-cell>
                  <md-table-cell md-label="Numéro de téléphone">{{
                    item.phone_number
                  }}</md-table-cell>
                  <md-table-cell md-label="Joiner le ">{{
                      new Date(item.created_at).toLocaleDateString('fr-FR',options = {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                      })
                  }}</md-table-cell>
                  <md-table-cell md-label="Action">
                    <router-link :to="`/inscription/acheteur/${item.id}`">
                      <md-button style="background-color: #1476b5 !important"
                     class="md-dense md-raised md-primary m-auto "
                        >Accepter</md-button
                      >
                    </router-link>
                    <md-button @click="changeRefuseModalState(item.id)"
                       style="background-color: #e0622a  !important"
                      class="md-dense md-raised md-primary m-auto"
                      >Refuser</md-button
                    >
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </div>
          </md-card-content>
        </md-card>
    
        <div
          class="relative z-10"
          style="z-index: 50000"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          :hidden="!RefuseModalOn"
      >
        <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        ></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
              class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <div
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div
                      class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
                    <svg
                        class="h-6 w-6 text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                      <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3
                        class="text-base font-semibold leading-6 text-gray-900"
                        id="modal-title"
                    >
                      Refuser l'acheteur
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Vous étes sur que vous voulez refuser cet acheteur
                        ?
                      </p>
                    </div>
                  </div>
                </div>
              </div>



              <div
                  class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
              >
                <button
                    @click="toggleInput"
                    :class="{ 'disabled:opacity-70': isLoading }"
                    type="button"
                    class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    :disabled="isLoading"
                >
                  <svg
                      v-if="isLoading"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="mr-2 animate-spin"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
                    ></path>
                  </svg>
                  Refuser
                </button>
                <button
                    @click="changeRefuseModalState(null)"
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Annuler
                </button>
              </div>




              <div class="sm:col-span-3">
            <label
            v-if="ShowHidden" 
              for="last-name"
              class="block text-sm font-medium leading-6 text-gray-700"
              >Entrer votre raison de refus: </label
            >
            <div
          v-if="AnyErrors && rejectedSuccess===false"
       
      >
        
        <p>
          {{ AnyErrors }}
        </p>
      </div>
            <div class="mt-2">
              <textarea
               
                v-if="ShowHidden" 
                v-model="refused_user.reason"
                name="nom-ar"
                id="nom-ar"
                autocomplete="family-name"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              ></textarea>
            </div>
          </div>


            <div
                  class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
              >
                <button
                v-if="ShowHidden" 
                    @click="Refuse(currentBuyer)"
                    :class="{ 'disabled:opacity-70': isLoading }"
                    type="button"
                    class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    :disabled="isLoading"
                >
                  <svg
                      v-if="isLoading"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="mr-2 animate-spin"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
                    ></path>
                  </svg>
                  Refuser
                </button>
                <!-- <button
                    @click="changeRefuseModalState(null)"
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Annuler
                </button> -->
              </div>


            </div>
          </div>
        </div>
      </div>



      </div>
    </div>
  </div>
</template>

<script>
// import { SimpleTable, OrderedTable } from "@/components";
import { store } from "../../../store/modules/GeneralStore.js";

export default {
  components: {},
  data() {
    return {
      RefuseModalOn: false,
      ShowHidden: false,
      inputValue: "", 
      currentBuyer: null,
     refused_user: {
      reason: null ,
     }
     , 
      
    };
  },
  computed: {
    isLoading() {
      return store.state.isLoading;
    },
    AnyErrors() {
      return store.state.error;
    },
    buyersToConfirm() {
      return store.state.buyerstoConfirm;
    },
    rejectedSuccess() {
      return store.state.rejectedSuccess;
    },
  },
  mounted: function () {
    store.dispatch("getBuyersToAdd");
    let context = this;
    if (sessionStorage.getItem("buyerSuccess")){
      context.$toasted.success(sessionStorage.getItem("buyerSuccess"),{
        duration: 5000,
        position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
        theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
        iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
      });
      sessionStorage.removeItem("buyerSuccess");
    }
    if (sessionStorage.getItem("buyerError")){
      context.$toasted.error(sessionStorage.getItem("buyerError"),{
        duration: 5000,
        position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
        theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
        iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
      });
      sessionStorage.removeItem("buyerError");
    }
    
  },
  methods: {
    toggleInput() {
      this.ShowHidden = !this.ShowHidden;
    },
    changeRefuseModalState(buyer_id) {
      this.RefuseModalOn = !this.RefuseModalOn;
      this.currentBuyer = buyer_id;
    },
    Refuse(buyer_id) {
      let context= this
      console.log('id',buyer_id)
      let payload  ={
          reason : context.refused_user.reason, 
          id : buyer_id
        } 
      
        console.log(this.currentBuyer)
        store.dispatch("RefuseBuyer", payload).then(
        function () {
            if(store.state.rejectedSuccess==true){
            
                  window.location = "/demandes/inscription/acheteurs";
                  context.RefuseModalOn = !context.RefuseModalOn;
                    context.refused_user={}
          }
          }
          
      );
     
      
        
    },
  },

};
</script>

<style></style>
