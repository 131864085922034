<style>
.hidden_input{
  display: none!important;
}
</style>
<template>
  <div v-if="isLoading " class="flex flex-col flex-1 gap-5 sm:p-2">
    <div class="flex flex-col flex-1 gap-3">
      <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
      <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
    </div>

    <div class="flex flex-col flex-1 gap-3">
      <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
      <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
    </div>
  </div>
  <div v-else class="p-9" >
    <div class="space-y-12 p-5 bg-white">
       


      <div class="flow-root">
                        <ul role="list" class="-my-6 divide-y divide-gray-200">
                          <li v-for="(item, index) in banners" :key="index" class="flex justify-around py-9 ">
                            
                            <div class=" flex flex-row">
                              <div >   
                                 <img   :src="item.media[0]?item.media[0].original_url: non_image"  />
                              </div>
                              <div class="ml-4 flex flex-1 flex-col">
                               
                                      
                                  <!-- <div class="flex flex-col lg:flex-row "> -->
                                          
                                    <div class="flex flex-row">
                                      <p class="">
                                        Position du Bannière : 
                                                    
                                      </p>
                                      <p>
                                         &nbsp;{{ item.position }}
                                      </p>
                                           
                                    </div>
                                    <div class="flex flex-row">
                                      <p class="">
                                        Lien : 
                                      </p>
                                         
                                      <p> 
                                        &nbsp;{{ item.link }}
                                      </p>
                                    </div>
                                    <div v-if="item.category_id" class="flex flex-row">
                                      <p class="">
                                        categorie : 
                                              
                                      </p>
                                      
                                    </div>
                                          
                                  <!-- </div> -->
                                
                              </div>
                            </div>
                            <div class="flex flex-col">


                         


                              <button>
                                <svg
                                @click="changeEditModalState(item.id)" :id="item.id"
                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                  <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                </svg>
                              </button>  
                            </div>
                            <div>     <label class="relative inline-flex items-center mr-5 cursor-pointer">
                                <input type="checkbox"  @click="updateState(index,item.id)" v-model="isActive[index]" class="sr-only peer" >
                                <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-orange-300 dark:peer-focus:ring-orange-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-500"></div>
                               </label></div>


                               
                          </li>

                        </ul>
      <div
                        class="relative z-10 bg-gray-100"
          style="z-index: 50000"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          :hidden="!DeleteModalOn"
      >
        <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        ></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
              class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <div
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
            <div
                      class="flex items-center cursor-pointer justify-end "
                  >
                  <svg  @click="changeEditModalState(currentBanner)" class="bi bi-x-lg  m-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                   </svg>
                  </div>
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
               
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3
                        class="text-base font-semibold leading-6 text-gray-900"
                        id="modal-title"
                    >
                      Modifier la bannière : 
                    </h3>
                    <div class="mt-2">
                      <div class="flex flex-row mt-6">
                        <p class="font-bold ml-3 mt-2.5 w-full ">
                        Position : 
                        
                      </p>
                  
                  <select v-model="bann.position"
                name="position"
                        id="position"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option
                v-for="item in banners"
                :value="item.position"
                :key="item.id">
                <div>
               {{ item.position}}</div>
                </option>
                </select>
                          </div>


                  <div class="flex flex-row mt-6">
                        <p class="font-bold ml-3 mt-2.5 w-full ">
                        link : 
                        
                      </p>
                      <input
                            type="text"
                            v-model="bann.link"
                            
                            class=" block w-3/5 rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" >
                  </div>

                  <div  class="flex flex-row mt-6">
                        <p class="font-bold ml-3 mt-2.5 w-full ">
                        catégorie : 
                        
                      </p>
                      <select v-model="bann.category_id"
                name="position"
                        id="position"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option
                v-for="item in categories"
                :value="item.id"
                :key="item.id">
                <div>
               {{ item.name_cat_fr}}</div>
                </option>
                </select>
                          </div>
                  
                  <div  class="flex flex-row mt-6">
                        <p class="font-bold ml-3 mt-2.5 w-full ">
                        status : 
                        
                      </p>
                      <div>     <label class="relative inline-flex items-center mr-5 cursor-pointer">
                                <input type="checkbox" v-model="isActiveById" class="sr-only peer" >
                                <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-orange-300 dark:peer-focus:ring-orange-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-500"></div>
                               </label></div>

                 </div>

                              <div v-if="!bann.image_url " class="text-center">
                  <div class="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      for="file-upload"
                      class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-black focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                    <span style="color: #e0622a !important">Importer une image</span>
                      <input
                        @change="onImageChanged"
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        class="sr-only"
                      />
                    </label>
                    <!--                  <p class="pl-1">or drag and drop</p>-->
                  </div>
                  <p class="text-xs leading-5 text-gray-600">PNG, JPG, GIF</p>
                </div>


                <div v-else>
                  <img style="height: 300px !important" id="catImg"  :src="bann.image_url? bann.image_url : non_image" />
                     <div class="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      for="file-upload"
                      class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-black focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                    <span style="color: #e0622a !important">Importer une image</span>
                      <input
                        @change="onImageChanged"
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        class="sr-only"
                      />
                    </label>
                    <!--                  <p class="pl-1">or drag and drop</p>-->
                  </div>
                </div>
                      <!-- <div >   
                                 <img   :src="bann.image_url?bann.image_url: non_image"  />
                              </div> -->

                      <!-- <p class="text-sm text-gray-500">
                        {{bann.position
                        }}
                      </p> -->
                    </div>
                  </div>
                </div>
              </div>








              <div
                  class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
              >
                <md-button
                    @click="update(bann)"
                    :class="{ 'disabled:opacity-70': isLoading }"
                    type="button"
                    style="background-color: #1476b5 !important"

                    :disabled="isLoading"
                >
            
                  Modifier
                </md-button>
            
              </div>
            </div>
          </div>
        </div>

       
        
      </div>

                      
                      
                      </div>
            </div>



            

            
      <div class="mt-6 flex items-center justify-end gap-x-6">
        <md-button
          :class="{ 'disabled:opacity-70': isLoading }"
          @click="changeAddModalState(true)" 
          type="submit"
          :disabled="isLoading"
          class="md-raised"
          style="background-color: #1476b5 !important" >
      
          Ajouter 
        </md-button>
      </div>
















      <div
                        class="relative z-10 bg-gray-100"
          style="z-index: 50000"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          :hidden="!addModalOn"
      >
        <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        ></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
              class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <div
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
            <div
                      class="flex items-center cursor-pointer justify-end "
                  >
                  <svg  @click="changeAddModalState()" class="bi bi-x-lg  m-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                   </svg>
                  </div>
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
               
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3
                        class="text-base font-semibold leading-6 text-gray-900"
                        id="modal-title"
                    >
                      Ajouter une bannière : 
                    </h3>
                    <div class="mt-2">
                      <div class="mt-2">
                      <div class="flex flex-row mt-6">
                        <p class="font-bold ml-3 mt-2.5 w-full ">
                        Position : 
                        
                      </p>
                 
                  <select v-model="addedbanner.position"
                name="position"
                        id="position"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option
                v-for="item in banners"
                :value="item.position"
                :key="item.id">
                <div>
               {{ item.position}}</div>
                </option>
                </select>
                          </div>


                  <div class="flex flex-row mt-6">
                        <p class="font-bold ml-3 mt-2.5 w-full ">
                        link : 
                        
                      </p>
                      <input
                            type="text"
                            v-model="addedbanner.link"
                            
                            class=" block w-3/5 rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" >
                  </div>

                  <div  class="flex flex-row mt-6">
                        <p class="font-bold ml-3 mt-2.5 w-full ">
                        catégorie : 
                        
                      </p>
                      <select v-model="addedbanner.category_id"
                name="position"
                        id="position"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option
                v-for="item in categories"
                :value="item.id"
                :key="item.id">
                <div>
               {{ item.name_cat_fr}}</div>
                </option>
                </select>
                          </div>
                  
                  <div  class="flex flex-row mt-6">
                        <p class="font-bold ml-3 mt-2.5 w-full ">
                        status : 
                        
                      </p>
                      <div>     <label class="relative inline-flex items-center mr-5 cursor-pointer">
                                <input type="checkbox" v-model="isActiveAdd" class="sr-only peer" >
                                <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-orange-300 dark:peer-focus:ring-orange-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-500"></div>
                               </label></div>

                 </div>

                 <div v-if="addedbanner.image_media === null" class="text-center">
                <div class="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    for="file-upload"
                    class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-black focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                  <span style="color: #e0622a !important">Importer media</span>
                    <input
                      @change="onMediaChanged"
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      class="sr-only"
                    />
                  </label>
                  <!--                  <p class="pl-1">or drag and drop</p>-->
                </div>
                <p class="text-xs leading-5 text-gray-600">PNG, JPG, GIF</p>
              </div>
              <div v-else>
                <img style="height: 300px !important" id="banImg" src="" />
                <div class="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      for="file-upload"
                      class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-black focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span style="color: #e0622a !important">Importer une image</span>
                      <input
                        @change="onMediaChanged"
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        class="sr-only"
                      />
                    </label>
                    <!--                  <p class="pl-1">or drag and drop</p>-->
                  </div>
              </div>
                      <!-- <div >   
                                 <img   :src="bann.image_url?bann.image_url: non_image"  />
                              </div> -->

                      <!-- <p class="text-sm text-gray-500">
                        {{bann.position
                        }}
                      </p> -->
                    </div>

                      <p class="text-sm text-gray-500">
                        <!-- {{banner.media}} -->
                      </p>
                    </div>
                  </div>
                </div>
              </div>








              <div
                  class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
              >
                <md-button
                    @click="addBanner()"
                    :class="{ 'disabled:opacity-70': isLoading }"
                    type="button"
                    :disabled="isLoading"
                    class="md-raised"
          style="background-color: #1476b5 !important" >

                
              
                  Ajouter
                </md-button>
                <!-- <button
                    @click="changeAddModalState(false)"
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Annuler
                </button> -->
              </div>
            </div>
          </div>
        </div>

       
        
      </div>

    </div>

</template>
  
  <script>
  import { store } from "@/store/modules/GeneralStore";
  import $ from "jquery";
  import ToastNotification from '../../components/ToastNotification';


  export default {
    components : {
      ToastNotification
    },
    data() {
      return {
        noe_image: require("@/assets/img/null.png"),
        non_image: require("@/assets/img/null.png"),
        id_ctg : null, 
        isActive:[],
        isActiveAdd:null,
        isActiveById:null,
          bann:{
            position :null,
            link:null, 
            category_id:null, 
            state:null,
            image_url:null
          },
          addedbanner:{
            position :null,
            link:null, 
            category_id:null, 
            state:null,    
            image_media:null

          },
        DeleteModalOn: false,
    
      addModalOn: false,
   
      currentBanner: null,
      };
    },

    computed: {
      isLoading() {
        return store.state.isLoading;
      },
      AnyErrors() {
        return store.state.error;
      },
      AnySuccess() {
        return store.state.success;
      },

      user(){
          return store.state.user;
      },
      banners(){
          return store.state.banners;
      },
      banner(){
          return store.state.banner;
      },
      categories() {
      return store.state.categories;
    },
    },
    mounted: function () {

      store.dispatch("getCategories")
      let context = this;
      
   if (sessionStorage.getItem("bannerSuccess")){
        context.$toasted.success(sessionStorage.getItem("bannerSuccess"),{
          duration: 5000,
          position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
          theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
          iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
        });
        sessionStorage.removeItem("bannerSuccess");
      }
    if (sessionStorage.getItem("bannerError")){
      context.$toasted.error(sessionStorage.getItem("bannerError"),{
        duration: 5000,
        position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
        theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
        iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
      });
      sessionStorage.removeItem("bannerError");
    }
    
       store.dispatch('availableBanners')
      .then(function(){

        for(let i=0; i<context.banners.length; i++){
          if( context.banners[i].state=="active"){
            context.isActive.push(true);
          }
          if( context.banners[i].state=="desactive"){
            context.isActive.push(false);
          }
        }
        console.log('console',context.banners)
        // for(let i=0; i< context.banners.length; i++){
        //   context.edit.push(false);
        //   console.log("wal3adawaaw",context.edit[i])
        // }
      });

    },
    methods: {

      changeEditModalState(banner_id) {
      this.DeleteModalOn = !this.DeleteModalOn;
      this.currentBanner = banner_id;
      console.log(this.currentBanner)
      let context= this
      store.dispatch('getBannerById',this.currentBanner).then(function (){
        console.log('banner', context.banner)
        
        context.bann= context.banner
        if( context.banner.state=="active"){
            context.isActiveById=true;
          }
          if( context.banner.state=="desactive"){
            context.isActiveById=false;
          }
        
      })
    },



    changeAddModalState() {
      this.addModalOn = !this.addModalOn;
      
    },

      onImageChanged(event) {
      

        this.bann.image_url = event.target.files[0];
        const image = this.bann.image_url;
        const reader = new FileReader();
        reader.onload = () => {
          const imgUrl = reader.result;
          document.getElementById("catImg").setAttribute("src", imgUrl);
          // console.log(imgUrl);
          this.bann.image_url = imgUrl;
          //img.src = imgurl;
        };
        reader.readAsDataURL(image);
      },

      test(){
        console.log("test")
      },



  onMediaChanged(event) {
      console.log("ay haja ")
    // this.addedbanner.image_media = event.target.files[0];
    //   const image = this.addedbanner.image_media;
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     const imgUrl = reader.result;
    //     document.getElementById("banImg").setAttribute("src", imgUrl);
    //     console.log(imgUrl);
    //     this.addedbanner.image_media = imgUrl;
    //     //img.src = imgurl;
    //   };
    //   reader.readAsDataURL(image);
    },





      updateState(index,id){
        let context = this
        console.log(index);
        store.dispatch('updateState',{
          state: this.isActive[index]?'desactive': 'active', 
          banner_id: id
        })


        .then(function (){
          if (sessionStorage.getItem("stateSuccess")){
        context.$toasted.success(sessionStorage.getItem("stateSuccess"),{
          duration: 5000,
          position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
          theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
          iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
        });
        sessionStorage.removeItem("stateSuccess");
      }
    if (sessionStorage.getItem("stateError")){
      context.$toasted.error(sessionStorage.getItem("stateError"),{
        duration: 5000,
        position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
        theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
        iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
      });
      sessionStorage.removeItem("stateError");
    }
      })
      },
      update(item) {
        // this.$set(this.edit, index, true);
        // console.log("ol",this.edit[index])
        // if( this.edit[index]==true){


          console.log('index , id',item)
          // console.log('index , id', this.banners[index].position)
          store.dispatch('updateBanner', 
          {state: this.isActiveById?'active':'desactive', 
          position : this.bann.position , 
          link: this.bann.link,
          category_id: this.bann.category_id?this.bann.category_id: null, 
          bunner_id: item.id,
          image_url: this.bann.image_url?this.bann.image_url:null
          }).then(function (){
            window.location = "/banners";
          })

        // }
     
    },
    addBanner() {
       
          store.dispatch('addBanner', 
          {state: this.isActiveAdd?'active':'desactive', 
          position : this.addedbanner.position , 
          link: this.addedbanner.link,
          category_id: this.addedbanner.category_id?this.addedbanner.category_id: null, 
         
          image_media: this.addedbanner.image_media?this.addedbanner.image_media:null
          }).then(function (){
            // window.location = "/banners";
          })

        // }
     
    },
    },
  };
  </script>
<style>


</style>

  