<template>
    <div v-if="isLoading || this.buyer.buyer.name===null" class="flex flex-col flex-1 gap-5 sm:p-2">
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>
  
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>
    </div>
  
    <form v-else class="p-5" @submit.prevent>
      <div class="space-y-12 p-5 bg-white">
 
        <div class="border-b border-gray-900/10 pb-12">
          <h2 class="text-base font-semibold leading-7 text-gray-900">
            Acheteurs
          </h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">
            Les champs avec la signe <span class="required">*</span> sont obligatoires !
          </p>
  
          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <label
                  for="name"
                  class="block text-sm font-medium leading-6 text-gray-900"
              >Nom <span class="required">*</span></label
              >
              <div class="mt-2">
                <input
                    type="text"
                    v-model="buyer.buyer.user.name"
                    name="name"
                    id="name"
                    autocomplete="name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
  
            <div class="sm:col-span-3">
              <label
                  for="email"
                  class="block text-sm font-medium leading-6 text-gray-900"
              >Email </label
              >
              <div class="mt-2">
                <input
                    type="email"
                    v-model="buyer.buyer.user.email"
                    name="email"
                    id="email"
                    autocomplete="email"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
           
            <div class="sm:col-span-3">
              <label
                  for="email"
                  class="block text-sm font-medium leading-6 text-gray-900"
              >Mot de passe <span class="required">*</span></label
              >
              <div class="mt-2">
                <input
                    type="text"
                    v-model="buy.password"
                    name="passwordpassword"
                    id="passwordpassword"
                    autocomplete="email"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
           
  
  
  
            <div class="sm:col-span-3">
              <label
                  for="company_name"
                  class="block text-sm font-medium leading-6 text-gray-900"
              >Nom de l'entreprise </label
              >
              <div class="mt-2">
                <input
                    type="text"
                    v-model="buyer.buyer.company_name"
                    name="company_name"
                    id="company_name"
                    autocomplete="company_name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
  
            <div class="sm:col-span-3">
              <label
                  for="phone_number"
                  class="block text-sm font-medium leading-6 text-gray-900"
              >Numéro de téléphone <span class="required">*</span></label
              >
              <div class="relative mt-2 rounded-md shadow-sm">
                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span class="text-gray-500 sm:text-sm font-bold">+212</span>
                </div>
                <input type="text" v-model="buyer.buyer.phone_number" name="phone_number" id="phone_number" class="block w-full rounded-md border-0 py-1.5  pl-12 pr-20   text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  />
                <div class="absolute inset-y-0 right-0 flex items-center">
                  <label for="currency" class="sr-only">Currency</label>
  
                </div>
              </div>
            </div>
  
            <div class="sm:col-span-3">
              <label
                  for="city"
                  class="block text-sm font-medium leading-6 text-gray-900"
              >Ville </label
              >
              <div class="mt-2">
                <input
                    type="text"
                    v-model="buyer.buyer.city"
                    name="city"
                    id="city"
                    autocomplete="city"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
  
            <div class="sm:col-span-3">
              <label
                  for="address"
                  class="block text-sm font-medium leading-6 text-gray-900"
              >Adresse</label
              >
              <div class="mt-2">
                <input
                    type="text"
                    v-model="buyer.buyer.address"
                    name="address"
                    id="address"
                    autocomplete="address"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
  
          <div class="sm:col-span-3 mt-5">
            <label
                for="description"
                class="block text-sm font-medium leading-6 text-gray-900"
            >Description
            </label>
            <div class="mt-2">
              <ckeditor v-model="buyer.buyer.description"
                        name="description"
                        id="description"></ckeditor>
            </div>
          </div>
        </div>
  
        <div class="border-b border-gray-900/10 pb-12">
          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="col-span-full">
              <label
                  for="cover-photo"
                  class="block text-sm font-medium leading-6 text-gray-900"
              >Media</label
              >
              <div
                  class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
              >
                <div :hidden="buyer.image_media !== null" class="text-center">
                  <div class="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                        for="file-upload"
                        class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-black focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span style="color: #e0622a !important">Importer media</span>
                      <input
                          @change="onMediaChanged"
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          class="sr-only"
                      />
                    </label>
                    <!--                  <p class="pl-1">or drag and drop</p>-->
                  </div>
                  <br>
                  <p class="text-xs leading-5 text-gray-600">PNG, JPG, GIF</p>
                </div>


                <div :hidden="buyer.image_media === null">

                
                  <img style="height: 300px !important" id="buyerImage"  :src="buyer.image_media? buyer.image_media : non_image" />
                  <video width="470" height="255" id="buyerVideo" style="display: none" controls>
                    <source :src="buyer.image_media" type="">
                  </video>
                  <div class="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      for="file-upload"
                      class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-black focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span style="color: #e0622a !important">Importer une image</span>
                      <input
                        @change="onMediaChanged"
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        class="sr-only"
                      />
                    </label>
                    <!--                  <p class="pl-1">or drag and drop</p>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="mt-6 flex items-center justify-end gap-x-6">
        <router-link :to="{ name: 'Utilisateurs / Acheteurs' }">
          <md-button  style="background-color: #e0622a !important">Annuler</md-button></router-link>
        <md-button
            :class="{ 'disabled:opacity-70': isLoading }"
            @click="create"
            type="submit"
            :disabled="isLoading"
            class="md-raised"
          style="background-color: #1476b5 !important"        >
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
                v-if="isLoading"
                width="20"
                height="20"
                fill="currentColor"
                class="mr-2 animate-spin"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
              ></path>
            </svg>
          </span>
          Modifier
        </md-button>
      </div>
    </form>
  </template>
  
  <script>
  // import { PhotoIcon, UserCircleIcon } from "@heroicons/vue/24/solid";
  
  import { store } from "@/store/modules/GeneralStore";
  import $ from "jquery";
  
  export default {
    components: {
      //   PhotoIcon,
      //   UserCircleIcon,
    },
    data() {
      return {
        buyer_id: null,
        non_image: require("@/assets/img/null.png"),
        buy:{
        media_file:null,
        password:null, 
      }
      };
    },
    computed: {
      isLoading() {
        return store.state.isLoading;
      },
      AnyErrors() {
        return store.state.error;
      },
      AnySuccess() {
        return store.state.success;
      },
      buyer(){
        return store.state.buyerbyid;
      }
      
    },
  
    mounted: function () {
      this.buyer_id = this.$route.params.id;
     
      store.dispatch("getBuyerById", this.buyer_id)
      console.log("this is buyer",this.buyer);
  
      // console.log(this.seller)
    },
    methods: {
      onMediaChanged(event) {
        console.log(this.user_id);
  
        var filePath = $("#file-upload").val();
        var imagedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        var videosExtensions = /(\.mp4|\.mov|\.ogg|\.webm)$/i;
        if (videosExtensions.exec(filePath)) {
          $('#buyerImage').css("display",'none');
          $('#buyerVideo').css("display",'');
          document.getElementById("buyerVideo").setAttribute("type", "video/mp4");
          this.buyer.image_media = event.target.files[0];
          this.buy.media_file = event.target.files[0];
          const media = this.buyer.image_media;
          const reader = new FileReader();
          reader.onload = () => {
            const mediaUrl = reader.result;
            document.getElementById("buyerVideo").setAttribute("src", mediaUrl);
            this.buyer.image_media = mediaUrl;
            //img.src = imgurl;
          };
          reader.readAsDataURL(media);
        }
        else if (imagedExtensions.exec(filePath)){
          $('#buyerImage').css("display",'');
          $('#buyerVideo').css("display",'none');
          this.buyer.image_media = event.target.files[0];
          // console.log("this.buyer.image_media", this.buyer.image_media)
          this.buy.media_file = event.target.files[0];
          console.log("this is media_file",this.buyer.image_media )
          // console.log("this.buyer.media_file ",this.buyer.media_file )
          const media = this.buyer.image_media;
          const reader = new FileReader();
          reader.onload = () => {
            const mediaUrl = reader.result;
            document.getElementById("buyerImage").setAttribute("src", mediaUrl);
            this.buyer.image_media = mediaUrl;
            console.log("this is media",this.buyer.image_media )
            //img.src = imgurl;
          };
          reader.readAsDataURL(media);
        }
      },
      create() {
        let context = this;
        let formData=new FormData();
        console.log("pass", this.buy.password)
        formData.append('name',this.buyer.buyer.user.name);
        if (this.buyer.buyer.user.email!==null) {
          formData.append('email', this.buyer.buyer.user.email);
        }
        formData.append('password',this.buy.password);
        if (this.buyer.buyer.company_name!==null) {
          formData.append('company_name', this.buyer.buyer.company_name);
        }
        if (this.buyer.buyer.phone_number!==null) {
          formData.append('phone_number', this.buyer.buyer.phone_number);
        }
        if (this.buyer.buyer.city!==null) {
          formData.append('city', this.buyer.buyer.city);
        }
        if (this.buyer.buyer.address!==null) {
          formData.append('address', this.buyer.buyer.address);
        }
        formData.append('description',this.buyer.buyer.description);
        formData.append('user_id',this.buyer_id);
        formData.append('media_url',this.buy.media_file);
        formData.append('buyer_id',this.buyer.buyer.id);
        store.dispatch("UpdateBuyer", formData).then(function (){
          setTimeout(function () {
            if (sessionStorage.getItem("buyerSuccess")){
            context.$toasted.success(sessionStorage.getItem("buyerSuccess")+" : <a style='color : black;margin-left: 10px' href='/utilisateurs/acheteurs'>consulter la liste des acheteurs confirmés ici</a>",{
              duration: 5000,
              position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
              theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
              iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
            });
            sessionStorage.removeItem("buyerSuccess");
          
          }
          if (sessionStorage.getItem("buyerError")){
            context.$toasted.error(sessionStorage.getItem("buyerError"),{
              duration: 5000,
              position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
              theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
              iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
            });
            sessionStorage.removeItem("buyerError");
          }
          },1500);

        });
      },
      
    },
  };
  </script>
  
  
  <style >
  .required {
    font-weight: bolder;
    color :red;
    /* You can also add more styles here if needed */
  }
  </style>