<style>
select[name="example_length"]{
  padding-right: 18px!important;
}
.dataTables_length{
  padding-bottom: 20px!important;
}
.divcontainer {
  overflow-x: scroll;
  overflow-y: auto;
  transform: rotateX(180deg);
}

.divcontainer table {
  transform: rotateX(180deg);
}
::-webkit-scrollbar { height: 8px;}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #e0622a;
}
</style>
<template>
  <div class="content">
    <div v-if="isLoading" class="flex flex-col flex-1 gap-5 sm:p-2">
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>

      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>
    </div>



    <div v-else class="md-layout">

      <div
          class="relative z-10"
          style="z-index: 50000"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          :hidden="!BulkConfirmModalOn"
      >
        <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        ></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
              class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <div
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div
                      class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
                    <svg
                        class="h-6 w-6 text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                      <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3
                        class="text-base font-semibold leading-6 text-gray-900"
                        id="modal-title"
                    >
                      Confirmer produits
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        vous êtes sur que vous voulez confirmer ces produits
                        ?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
              >
                <button
                    @click="bulkConfirm()"
                    :class="{ 'disabled:opacity-70': isLoading }"
                    type="button"
                    class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    :disabled="isLoading"
                >
                  <svg
                      v-if="isLoading"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="mr-2 animate-spin"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
                    ></path>
                  </svg>
                  Confirmer
                </button>
                <button
                    @click="changeBulkConfirmModalState()"
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
          class="relative z-10"
          style="z-index: 50000"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          :hidden="!BulkRefuseModalOn"
      >
        <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        ></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
              class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <div
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div
                      class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
                    <svg
                        class="h-6 w-6 text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                      <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3
                        class="text-base font-semibold leading-6 text-gray-900"
                        id="modal-title"
                    >
                      Refuser produits
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        vous êtes sur que vous voulez refuser ces produits
                        ?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
              >
                <button
                    @click="bulkRefuse()"
                    :class="{ 'disabled:opacity-70': isLoading }"
                    type="button"
                    class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    :disabled="isLoading"
                >
                  <svg
                      v-if="isLoading"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="mr-2 animate-spin"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
                    ></path>
                  </svg>
                  Refuser
                </button>
                <button
                    @click="changeBulkRefuseModalState()"
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >


        <div
            style="padding: unset !important"
            class="container col-12 w-100 bg-danger"
        >
          <div class="w-100" style="width: 100% !important">
            <div
                class="flex flex-row justify-between w-full w-100 mb-1 sm:mb-0"
                style="width: 100% !important"
            >
              <h2 class="text-2xl leading-tight"></h2>
          
            </div>
            <div class="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
              <div
                  class="inline-block min-w-full overflow-hidden rounded-lg shadow"
              >
               
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex w-full p-4" style="margin-top: -50px!important;">
        <div class="w-1/2 sm:w-full p-2">
          <div class="mt-2">
            <input
                v-model="reference_product"
                type="text"
                name="reference_tawdaw"
                placeholder="Référence de Produit"
                id="reference_tawdaw"
                autocomplete="given-name"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div class="w-1/2 sm:w-full p-2">
          <div class="mt-2">
            <select v-model="vendor_id"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <!--              <optgroup v-for="seller in sellers" :label="'Catégorie - ' + category.name_cat_fr" :key="category.id">-->
              <option  selected value="">Selectionner Vendeur</option>
              <option value="tawdaw">Tawdaw</option>
              <option v-for="seller in sellers" :value="seller.user.id" :key="seller.user.id">{{ seller.user.name }}</option>
              <!--              </optgroup>-->
            </select>
            <!--            <input-->
            <!--                type="text"-->
            <!--                placeholder="Vendeur"-->
            <!--                name="reference_tawdaw"-->
            <!--                id="reference_tawdaw"-->
            <!--                autocomplete="given-name"-->
            <!--                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"-->
            <!--            />-->
          </div>
        </div>
      </div>

      <div class="flex w-full p-4" style="margin-top: -30px!important;">
        <div class="w-1/2 sm:w-full p-2">
          <div class="">
            <select v-model="sub_category_id"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <option  selected value="">Selectionner Sous categorie</option>
              <optgroup v-for="category in categories" :label="'Catégorie - ' + category.name_cat_fr" :key="category.id">
                <option v-for="subCategory in category.subcategories" :value="subCategory.id" :key="subCategory.id">{{ subCategory.name_scat_fr }}</option>
              </optgroup>
            </select>
            <!--            <input-->
            <!--                type="text"-->
            <!--                placeholder="Sous categorie"-->
            <!--                name="reference_tawdaw"-->
            <!--                id="reference_tawdaw"-->
            <!--                autocomplete="given-name"-->
            <!--                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"-->
            <!--            />-->
          </div>
        </div>
        <div class="w-1/2 sm:w-full p-2"></div>
      </div>

      <md-button
          @click="filter()"
          class="md-raised ml-4 "
          style="background-color: #1476b5 !important;margin-top: -5px!important;margin-left: 20px!important;"
      >Filtrer</md-button
      >
      <md-button
          @click="clear()"
          class="md-raised ml-4 "
          style="background-color: #e0622a !important;margin-top: -5px!important;margin-left: 20px!important;"
      >Clear</md-button
      >


   
      <md-card>
        <md-card-header data-background-color="orange">
          <h4 class="title">Liste des produits à confirmer</h4>
        </md-card-header>
        <md-card-content>
          <!--            <simple-table :tableData="categories" ></simple-table>-->
          <div class="divcontainer">
            <md-table style="text-align: center" v-model="unconfirmedProducts" table-header-color="orange">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell class="text-center" md-label="">
                  <input
                      class="item_id_checkbox w-4 h-4 text-gray-600 bg-gray-100 border-gray-300 rounded focus:ring-gray-500 dark:focus:ring-gray-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      type="checkbox" v-model="selectedRows"  :value="item.id"></md-table-cell>
                <md-table-cell md-label="Image">
                  <img style="height: 100px!important;width: 100px!important;" :src="item.image?item.image.original_url:non_image"/>
                </md-table-cell>
                <md-table-cell md-label="Nom">{{
                    item.name_product
                  }}</md-table-cell>
                <md-table-cell md-label="Type">{{
                    item.type_price
                  }}</md-table-cell>
                <md-table-cell md-label="Qte en stock">{{
                    item.qte_stock
                  }}</md-table-cell>
                <md-table-cell md-label="Qte min vente">{{
                    item.qte_min_vente??"Vide"
                  }}</md-table-cell>
                <md-table-cell md-label="Sous categorie">{{
                    item.sub_category!==null?item.sub_category.name_scat_fr:"Sans categorie"
                  }}</md-table-cell>
                <md-table-cell md-label="Marque">{{
                    item.brand!==null?item.brand.brand_name:"Vide"
                  }}</md-table-cell>
                <md-table-cell md-label="Createur">{{
                    item.creator.name
                  }}</md-table-cell>
                <md-table-cell class="items-center "  md-label="Action">
                    <md-button
                    style="background-color: #1476b5 !important"
                    @click="Confirm(item.id)" class="md-dense md-raised md-primary m-auto mr-1.5" >
                      Confirmer</md-button>
                 
                  <md-button
                  style="background-color: #e0622a  !important"
                    @click="Refuse(item.id)" class="md-dense md-raised md-primary m-auto">
                    Refuser</md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </md-card-content>
      </md-card>

    </div>

    <div class="w-full flex">
      <p class=" p-4">
        <a class="cursor-pointer mr-4" @click="selectAllItems()">tout sélectionner</a>
        /
        <a class="cursor-pointer ml-4" @click="unselectAllItems()">tout déselectionner</a></p>
      <md-button
          v-if="this.selectedRows.length>0"
          @click="changeBulkConfirmModalState()"
          class="md-raised mt-[80px]"
          style="background-color: #000000 !important;margin-top: -5px!important;margin-left: 20px!important;"
      >Confirmer </md-button
      >
      <md-button
          v-if="this.selectedRows.length>0"
          @click="changeBulkRefuseModalState()"
          class="md-raised mt-[80px]"
          style="background-color: #000000 !important;margin-top: -5px!important;margin-left: 20px!important;"
      >Refuser </md-button
      >
    </div>


    <md-button
        v-if="curPage!==1"
        class="md-raised"
        style="background-color: #1476b5 !important"
        @click="previousPage()"
    ><<</md-button
    >
    <md-button
        class="md-raised"
        style="background-color: #1476b5 !important"
        v-if="curPage!==totalPages"
        @click="nextPage()"
    >>></md-button>
    <br>
    page {{curPage}} sur {{totalPages}}
  </div>
</template>

<script>
import { store } from "../../store/modules/GeneralStore.js";
import $ from "jquery";
import jQuery from "jquery";
import DataTable from 'datatables.net-dt';
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-dt/css/jquery.dataTables.css"


export default {
  components: {
  
  },
  data() {
    return {
      BulkConfirmModalOn: false,
      BulkRefuseModalOn: false,
      currentProduct: null,
      non_image: require("@/assets/img/null.png"),
      sub_category_id: "",
      vendor_id: "",
      reference_product: null,
      selectedRows: [],
    };
  },
  computed: {
    isLoading() {
      return store.state.isLoading;
    },
    AnyErrors() {
      return store.state.error;
    },
    AnySuccess() {
      return store.state.success;
    },
    unconfirmedProducts() {
      return store.state.unconfirmedProducts;
    },
    curPage(){
      return store.state.curPage;
    },
    totalPages(){
      return store.state.totalPages;
    },
    categories(){
      return store.state.categories;
    },
    sellers(){
      return store.state.sellers;
    },
  },
  mounted: function () {
    let context = this;
      if (sessionStorage.getItem("productSuccess")){
        context.$toasted.success(sessionStorage.getItem("productSuccess"),{
          duration: 5000,
          position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
          theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
          iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
        });
        sessionStorage.removeItem("productSuccess");
      }
    if (sessionStorage.getItem("productError")){
      context.$toasted.error(sessionStorage.getItem("productError"),{
        duration: 5000,
        position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
        theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
        iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
      });
      sessionStorage.removeItem("productError");
    }
    //store.dispatch("getUnconfirmedProducts",{page:1});
    store.dispatch("filterUnconfirmedProducts",{page:1});
    store.dispatch("getCategoriesWithSubCategories");
    store.dispatch("getSellers");
  },
  methods: {
    // nextPage(){
    //   store.dispatch("getUnconfirmedProducts",{page:(this.curPage+1)});
    // },
    // previousPage(){
    //   store.dispatch("getUnconfirmedProducts",{page:(this.curPage-1)});
    // },
    nextPage(){
      store.dispatch("filterUnconfirmedProducts",{page:(this.curPage+1),reference_product:this.reference_product,
        sub_category_id:this.sub_category_id,creator_id:this.vendor_id});
    },
    previousPage(){
      store.dispatch("filterUnconfirmedProducts",{page:(this.curPage-1),reference_product:this.reference_product,
        sub_category_id:this.sub_category_id,creator_id:this.vendor_id});
    },
    filter(){
      store.dispatch("filterUnconfirmedProducts",{page:1,reference_product:this.reference_product,
        sub_category_id:this.sub_category_id,creator_id:this.vendor_id});
    },
    clear(){
      this.reference_product=null;
      this.sub_category_id=null;
      this.vendor_id=null;
      store.dispatch("filterUnconfirmedProducts",{page:1});
    },
    Confirm(product_id){
      console.log(product_id);
      let payload  ={
          
          id : product_id
        } 
      store.dispatch("ConfirmProduct", payload).then(
        function () {
                  window.location = "/produits/non-confirmes";
          }  
      );

    },
    Refuse(product_id){
      console.log(product_id);
      
      let payload  ={
          
          id : product_id
        } 
      store.dispatch("RefuseProduct", payload).then(
        function () {
                  window.location = "/produits/non-confirmes";
                }
        );
      },
    selectAllItems(){
      // alert();
      const selectAllCheckbox = document.getElementById('select_checkbox');
      const checkboxes = document.querySelectorAll('.item_id_checkbox');
      //if(selectAllCheckbox.checked){
      checkboxes.forEach(checkbox => {
        checkbox.checked = true;
        var changeEvent = new Event('change', { bubbles: true });

        // Dispatch the change event on the select element
        checkbox.dispatchEvent(changeEvent);
      });
      //}

      console.log("checked_items",this.selectedRows);
    },
    unselectAllItems(){
      // alert();
      const selectAllCheckbox = document.getElementById('select_checkbox');
      const checkboxes = document.querySelectorAll('.item_id_checkbox');
      //if(selectAllCheckbox.checked){
      checkboxes.forEach(checkbox => {
        checkbox.checked = false;
        var changeEvent = new Event('change', { bubbles: true });

        // Dispatch the change event on the select element
        checkbox.dispatchEvent(changeEvent);
      });
      //}
      console.log("checked_items",this.selectedRows);
    },
    changeBulkConfirmModalState() {
      this.BulkConfirmModalOn = !this.BulkConfirmModalOn;
    },
    changeBulkRefuseModalState() {
      this.BulkRefuseModalOn = !this.BulkRefuseModalOn;
    },
    bulkConfirm() {
      let context = this;
      this.BulkConfirmModalOn = !this.BulkConfirmModalOn;
      store.dispatch("ConfirmBulkProduct", {products_id:this.selectedRows,reference_product:this.reference_product,
        sub_category_id:this.sub_category_id,vendor_id:this.vendor_id}).then(
          function () {
            context.selectedRows = [];
            if (sessionStorage.getItem("productSuccess")){
              context.$toasted.success(sessionStorage.getItem("productSuccess"),{
                duration: 5000,
                position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
                theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
                iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
              });
              sessionStorage.removeItem("productSuccess");
            }
            if (sessionStorage.getItem("productError")){
              context.$toasted.error(sessionStorage.getItem("productError"),{
                duration: 5000,
                position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
                theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
                iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
              });
              sessionStorage.removeItem("productError");
            }          }
      );
    },
    bulkRefuse() {
      let context = this;
      this.BulkRefuseModalOn = !this.BulkRefuseModalOn;
      store.dispatch("BulkRefuseProduct", {products_id:this.selectedRows,reference_product:this.reference_product,
        sub_category_id:this.sub_category_id,vendor_id:this.vendor_id}).then(
          function () {
            context.selectedRows = [];
            if (sessionStorage.getItem("productSuccess")){
              context.$toasted.success(sessionStorage.getItem("productSuccess"),{
                duration: 5000,
                position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
                theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
                iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
              });
              sessionStorage.removeItem("productSuccess");
            }
            if (sessionStorage.getItem("productError")){
              context.$toasted.error(sessionStorage.getItem("productError"),{
                duration: 5000,
                position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
                theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
                iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
              });
              sessionStorage.removeItem("productError");
            }          }
      );
    },


  },
};
</script>
