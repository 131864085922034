import router from "../../routes/routes";
import axios from "axios";
// import store from "../index.js";
import Vue from "vue";
import Vuex from  "vuex";

export const store = new Vuex.Store({
  state: {
    nonePricedProductsCount : null,
    error: null,
    success: null,
    isLoading: false,
    user: JSON.parse(sessionStorage.getItem("userData")) ?? null,
    token: sessionStorage.getItem("token") ?? null,
    //urlPrefix: "http://127.0.0.1:8000",
    urlPrefix: "https://app.tawdaw.com",
    usersDropDown: false,
    inscriptionDemandDropDown: false,
    productsDropDown: false,
    admincommandsDropDown:false,
    sellercommandsDropDown:false,  
    categories: [],
    subCategories: [],
    subCategoriesByCategory: [],
    admins: [],
    buyers: [],
    sellers: [],
    buyerstoConfirm: [],
    sellerstoConfirm: [],
    sellerbyid:null,
    buyerbyid:null,
    brands:[], 
    confirmedProducts: [],
    unconfirmedProducts: [],
    characteristics: [],
    importProgress: '100%',
    notPassedData: 0,
    totalStatistics: null,
    totalOwnStatistics: null,
    totalSalesPerMonth: [],
    category:null, 
    subcategory:null, 
    product:null, 
    confirmedbrands:[], 
    pendingbrands: [], 
    nonconfirmedbrands: [], 
    brand:null, 
    brandDropDown:false,
    salesperMonth:[],
    sellersalesperMonth:[],
    countCommandsPerDeliveryMode:[],
    countSellerCommandsPerDeliveryMode:[],
    rejectedSuccess:false,
    admin:null,
    sellerproducts:[], 
    sellermanagers:[], 
    sellermanager: null, 
    admindevis: [], 
    sellerdevis: [], 
    confirmedsellercommand: null,
    confirmedadmincommand: null,
    confirmedsellercommands: [],
    confirmedadmincommands: [],  
    pendingsellercommands: [],
    pendingadmincommands: [], 
    productwithoutpriceByDevisId:[],
    paymentmodes:[],
    deliverymodes:[],
    searchedProducts : [],
    promotedProducts : [],
    unconfirmedSellercommands:[],
    unconfirmedAdmincommands:[],
    banners:[],
    banner:null,
    curPage : 1,
    totalPages : 1,
  },
  getters: {
    CurUser(state) {
      return state.user;
    },
    AnyErrors(state) {
      return state.error;
    },
    AnySuccess(state) {
      return state.success;
    },
    isLoading(state) {
      return state.isLoading;
    },
    token(state) {
      return state.token;
    },
    urlPrefix(state) {
      return state.urlPrefix;
    },
    usersDropDown(state) {
      return state.usersDropDown;
    },
    productsDropDown(state) {
      return state.productsDropDown;
    },
    categories(state) {
      return state.categories;
    },
    subCategories(state) {
      return state.subCategories;
    },
    subCategoriesByCategory(state) {
      return state.subCategoriesByCategory;
    },
    admins(state) {
      return state.admins;
    },
    buyers(state) {
      return state.buyers;
    },
    sellers(state) {
      return state.sellers;
    },
    buyerstoConfirm(state) {
      return state.buyerstoConfirm;
    },
    sellerstoConfirm(state) {
      return state.sellerstoConfirm;
    },
    sellerbyid(state) {
      return state.sellerbyid;
    },
    buyerbyid(state) {
      return state.buyerbyid;
    },
    brands(state) {
      return state.brands;
    }, 
    confirmedProducts(state) {
      return state.confirmedProducts;
    },
    unconfirmedProducts(state) {
      return state.unconfirmedProducts;
    },
    characteristics(state) {
      return state.characteristics;
    },
    ImportProgress(state){
      return state.importProgress;
    },
    NotPassedData(state){
      return state.notPassedData;
    },
    totalStatistics(state) {
      return state.totalStatistics;
    },
    totalOwnStatistics(state) {
      return state.totalOwnStatistics;
    },
    confirmedbrands(state) {
      return state.confirmedbrands;
    },
    pendingbrands(state) {
      return state.pendingbrands;
    },
    nonconfirmedbrands(state) {
      return state.nonconfirmedbrands;
    },
    brand(state) {
      return state.brand;
    },
    totalSalesPerMonth(state) {
      return state.totalSalesPerMonth;
    },
    countCommandsPerDeliveryMode(state) {
      return state.countCommandsPerDeliveryMode;
    },
    countSellerCommandsPerDeliveryMode(state) {
      return state.countSellerCommandsPerDeliveryMode;
    }
    ,category(){
      return state.category;

    },
    subcategory(){
      return state.subcategory;

    },
    product(state) {
      return state.product;
    },
    salesperMonth(state) {
      return state.salesperMonth;
    },
    sellerSalesperMonth(state) {
      return state.sellersalesperMonth;
    },
    rejectedSuccess(state){
      return state.rejectedSuccess;
     },
     admin(state){
      return state.admin;
     },
     sellerproducts(state){
      return state.sellerproducts;
     },
     sellermanagers(state){
      return state.sellermanagers;
     },

     sellermanager(state){
      return state.sellermanager;
     },
     admindevis(state){
      return state.admindevis;
     },
     sellerdevis(state){
      return state.sellerdevis;
     },
     confirmedsellercommands(state){
      return state.confirmedsellercommands;
     },
     confirmedadmincommands(state){
      return state.confirmedadmincommands;
     },
     pendingsellercommands(state){
      return state.pendingsellercommands;
     },
     pendingadmincommands(state){
      return state.pendingadmincommands;
     },
     confirmedsellercommand(state){
      return state.confirmedsellercommand;
     },
     confirmedadmincommand(state){
      return state.confirmedadmincommand;
     },
     productwithoutpriceByDevisId(state){
      return state.productwithoutpriceByDevisId;
     },

     deliverymodes(state){
      return state.deliverymodes;
     },
     paymentmodes(state){
      return state.paymentmodes;
     },
     unconfirmedSellercommands(state){
      return state.unconfirmedSellercommands;
     },
     unconfirmedAdmincommands(state){
      return state.unconfirmedAdmincommands;
     },
      banners(state){
      return state.banners;
     },
     initNonpricedProducts(state){
      let nonpriced= []
      for(let i=0; i<state.productwithoutpriceByDevisId.length; i++) {
        nonpriced.push({
          id_nonpriced_product:state.productwithoutpriceByDevisId[i].products.id,
          price:0,
          qte_cmd:0
        })
      }
      return nonpriced

     },
    searchedProducts(state){
      return state.searchedProducts;
    },
    promotedProducts(state){
      return state.promotedProducts;
    }
    ,banner(state){
      return state.banner;
    },
    curPage(state){
      return state.curPage;
    },
    totalPages(state){
      return state.totalPages;
    }
  
  },
  mutations: {
    
    Login(state, result) {
      state.user = result.data.userData;
      state.token = result.data.token;
      sessionStorage.setItem("token", result.data.token);
      sessionStorage.setItem("userData", JSON.stringify(result.data.userData));
    },
    Logout(state) {
      state.user = null;
      state.token = null;
    },
    Loading(state) {
      state.isLoading = !state.isLoading;
    },
    catchErrors(state, error) {
      state.error = error;
    },
    clearErrors(state) {
      state.error = null;
    },
    catchSuccess(state, success) {
      state.success = success;
    },
    clearSuccess(state) {
      state.success = null;
    },
    changeUserDropDownState(state) {
      state.usersDropDown = !state.usersDropDown;
    },
    changeProductsDropDownState(state) {
      state.productsDropDown = !state.productsDropDown;
    },
    fillCategories(state, categories) {
      state.categories = categories;
    },
    fillSubCategories(state, subCategories) {
      state.subCategories = subCategories;
    },
    fillSubCategoriesByCategory(state, subCategoriesByCategory) {
      state.subCategoriesByCategory = subCategoriesByCategory;
    },
    fillAdmins(state, admins) {
      state.admins = admins;
    },
    fillBuyers(state, buyers) {
      state.buyers = buyers;
    },
    fillBuyerstoConfirm(state, buyerstoConfirm) {
      state.buyerstoConfirm = buyerstoConfirm;
    },
    fillSellers(state, sellers) {
      state.sellers = sellers;
    },
    fillSellerstoConfirm(state, sellerstoConfirm) {
      state.sellerstoConfirm = sellerstoConfirm;
    },
    fillBuyerById(state, buyerbyid) {
      state.buyerbyid  = buyerbyid ;
    },
    fillSellerById(state, sellerbyid) {
      state.sellerbyid  = sellerbyid ;
    },
    fillBrands(state, brands) {
      state.brands  = brands ;
    },
    fillTotalStatistics(state, totalStatistics) {
      state.totalStatistics  = totalStatistics ;
    },
    fillTotalOwnStatistics(state, totalOwnStatistics) {
      state.totalOwnStatistics  = totalOwnStatistics ;
    },
    
    filltotalSalesPerMonth(state, totalSalesPerMonth) {
      state.totalSalesPerMonth  = totalSalesPerMonth ;
    },
    fillCountCommandsPerDeliveryMode(state, countCommandsPerDeliveryMode) {
      state.countCommandsPerDeliveryMode  = countCommandsPerDeliveryMode ;
    },
    fillCountSellerCommandsPerDeliveryMode(state, countSellerCommandsPerDeliveryMode) {
      state.countSellerCommandsPerDeliveryMode  = countSellerCommandsPerDeliveryMode ;
    },
    fillCategoryById(state,category){
      state.category  = category ;

    },
    fillSubCategoryById(state,subcategory){
      state.subcategory  = subcategory ;

    },

    fillProductById(state, product) {
      state.product  = product ;
    },
    fillConfirmedBrands(state, confirmedbrands) {
      state.confirmedbrands  = confirmedbrands ;
    },
     fillPendingBrands(state, pendingbrands) {
      state.pendingbrands  = pendingbrands ;
    }, 
    fillNonConfirmedBrands(state, nonconfirmedbrands) {
      state.nonconfirmedbrands  = nonconfirmedbrands ;
    }, 
    fillBrandById(state, brand) {
      state.brand  = brand ;
    },
    fillSalesPerMonth(state, salesperMonth) {
      state.salesperMonth  = salesperMonth ;
    },
    fillSellerSalesPerMonth(state, sellerSallesPerMonth) {
      state.sellersalesperMonth  = sellerSallesPerMonth ;
    },
    fillAdminById(state, admin) {
      state.admin  = admin ;
    },
    fillSellermanagers(state, sellermanagers){
      state.sellermanagers  = sellermanagers ;

    },
    fillSellerManagerById(state, sellermanager){
      state.sellermanager  = sellermanager ;

    },
    fillAdminDevis(state, admindevis){
      state.admindevis  = admindevis ;

    },
    fillSellerDevis(state, sellerdevis){
      state.sellerdevis  = sellerdevis ;

    },
    fillPendingSellerCommand(state, pendingsellercommands){
      state.pendingsellercommands  = pendingsellercommands ;

    },
    fillPendingAdminCommand(state, pendingadmincommands){
      state.pendingadmincommands  = pendingadmincommands ;

    },
    fillConfirmedSellerCommand(state, confirmedsellercommands){
      state.confirmedsellercommands  = confirmedsellercommands ;

    },
    fillConfirmedAdminCommand(state, confirmedadmincommands){
      state.confirmedadmincommands  = confirmedadmincommands ;

    },
    fillConfirmedSellerCommandById(state, confirmedsellercommand){
      state.confirmedsellercommand  = confirmedsellercommand ;

    },
    fillConfirmedAdminCommandById(state, confirmedadmincommand){
      state.confirmedadmincommand  = confirmedadmincommand ;

    },
    fillProductWithoutPriceByDevisId(state, productwithoutpriceByDevisId){
      state.productwithoutpriceByDevisId  = productwithoutpriceByDevisId ;

    },
    fillDeliveryModes(state, deliverymodes){
      state.deliverymodes  = deliverymodes ;

    },
    fillPaymentModes(state, paymentmodes){
      state.paymentmodes  = paymentmodes ;

    },
    fillUnconfirmedSellerCommand(state, unconfirmedSellercommands){
      state.unconfirmedSellercommands  = unconfirmedSellercommands ;

    },
    fillUnconfirmedAdminCommand(state, unconfirmedAdmincommands){
      state.unconfirmedAdmincommands  = unconfirmedAdmincommands ;

    },
     fillBanners(state, banners){
      state.banners  = banners ;

    },
    fillBanner(state, banners){
      state.banner  = banners ;

    },
    fillConfirmedProducts(state, data) {
      let products = [];
      for (let i=0;i<data.length;i++){
        console.log("enter");
        let product = {
          id : data[i].id,
          reference_tawdaw : data[i].reference_tawdaw,
          reference_product : data[i].reference_product,
          name_product : data[i].name_product,
          description : data[i].description,
          type_price : data[i].type_price==="priced"?"Avec prix":"Sans prix",
          qte_stock : data[i].qte_stock,
          qte_min_vente : data[i].qte_min_vente??null,
          virtual_price : data[i].products_with_price !== null ? data[i].products_with_price.virtual_price : null ,
          reel_price : data[i].products_with_price !== null ? data[i].products_with_price.reel_price : null ,
          image : data[i].media.length>0? data[i].media[0]: null ,
          sub_category : data[i].sub_category!==null ? {
            id : data[i].sub_category.id,
            name_scat_fr : data[i].sub_category.name_scat_fr,
            category : {
              id : data[i].sub_category.category.id,
              name_cat_fr : data[i].sub_category.category.name_cat_fr,
            }
          } : null,
          brand : data[i].brand? {
            id : data[i].brand.id,
            brand_name : data[i].brand.brand_name,
          }:null,
          creator : {
            id : data[i].creator.id,
            name : data[i].creator.roles[0].name==="Admin"? "Tawdaw" : data[i].creator.name,
          }

        }
        console.log(product);
        products.push(product);
      }
      state.confirmedProducts = products
    },
    fillUnconfirmedProducts(state, data) {
      let products = [];
      for (let i=0;i<data.length;i++){
        let product = {
          id : data[i].id,
          reference_tawdaw : data[i].reference_tawdaw,
          reference_product : data[i].reference_product,
          name_product : data[i].name_product,
          description : data[i].description,
          type_price : data[i].type_price==="priced"?"Avec prix":"Sans prix",
          qte_stock : data[i].qte_stock,
          qte_min_vente : data[i].qte_min_vente,
          virtual_price : data[i].products_with_price !== null ? data[i].products_with_price.virtual_price : null ,
          reel_price : data[i].products_with_price !== null ? data[i].products_with_price.reel_price : null ,
          image : data[i].media.length>0? data[i].media[0]: null ,
          sub_category : data[i].sub_category!==null ? {
            id : data[i].sub_category.id,
            name_scat_fr : data[i].sub_category.name_scat_fr,
            category : {
              id : data[i].sub_category.category.id,
              name_cat_fr : data[i].sub_category.category.name_cat_fr,
            }
          } : null,
          brand : data[i].brand? {
            id : data[i].brand.id,
            brand_name : data[i].brand.brand_name,
          }:null,
          creator : {
            id : data[i].creator.id,
            name : data[i].creator.roles[0].name==="Admin"? "Tawdaw" : data[i].creator.name,
          }

        }
        products.push(product);
      }
      state.unconfirmedProducts = products;
    },

    fillCharacteristics(state, characteristics) {
      state.characteristics  = characteristics ;
    },
    fillSellerProducts(state, data) {
      let products = [];
      for (let i=0;i<data.length;i++){
        console.log("enter");
        let product = {
          id : data[i].id,
          reference_tawdaw : data[i].reference_tawdaw,
          reference_product : data[i].reference_product,
          name_product : data[i].name_product,
          description : data[i].description,
          status : data[i].status,
          type_price : data[i].type_price==="priced"?"Avec prix":"Sans prix",
          qte_stock : data[i].qte_stock,
          qte_min_vente : data[i].qte_min_vente??null,
          virtual_price : data[i].products_with_price !== null ? data[i].products_with_price.virtual_price : null ,
          reel_price : data[i].products_with_price !== null ? data[i].products_with_price.reel_price : null ,
          image : data[i].media.length>0? data[i].media[0]: null ,
          sub_category : data[i].sub_category!==null ? {
            id : data[i].sub_category.id,
            name_scat_fr : data[i].sub_category.name_scat_fr,
            // category : {
            //   id : data[i].sub_category.category.id,
            //   name_cat_fr : data[i].sub_category.category.name_cat_fr,
            // }
          } : null,
          brand : data[i].brand? {
            id : data[i].brand.id,
            brand_name : data[i].brand.brand_name,
          }:null,
          // creator : {
          //   id : data[i].creator.id,
          //   name : data[i].creator.roles[0].name==="Admin"? "Tawdaw" : data[i].creator.name,
          // }

        }
        console.log(product);
        products.push(product);
      }
      
      state.sellerproducts  = products ;
    },
    setImportProgress(state , value){
      state.importProgress = value;
    },
    setNotPassedData(state , value){
      state.notPassedData = value;
    },
    fillsearchedProducts(state,searchedProducts){
      state.searchedProducts = searchedProducts;
    },
    fillpromotedProducts(state,promotedProducts){
      let promoted = [];
      for(let i=0;i<promotedProducts.length;i++){
        promoted.push({
          id : promotedProducts[i].product_id,
          media : promotedProducts[i].product.media,
          name_product : promotedProducts[i].product.name_product
        });
      }
      state.promotedProducts = promoted;
    },
    setcurPage(state , curPage){
      state.curPage = curPage;
    },
    settotalPages(state , totalPages){
      state.totalPages = totalPages;
    },

  },
  actions: {

    changeImportProgress({commit} ,value){
      store.commit("setImportProgress",value);
    },

    //Auth
    async Login({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "post",
          url: store.state.urlPrefix + "/api/login",
          data: {
            email: payload.email,
            password: payload.password,
          },
          headers: {},
        });

        commit("Loading");

        if (result.data.code === 200) {
          store.commit("Login", result);
          window.location = "/";
        } else if (result.data.code === 422) {
          let errorMsg = "| ";
          for (let key in result.data.message) {
            errorMsg += result.data.message[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
        } else {
          commit("catchErrors", result.data.message);
        }
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
      }
    },
    async Register({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "post",
          url: store.state.urlPrefix + "/api/inscripSeller",
          data: {
            name: payload.name,
            phone_number: payload.phone,
          },
          headers: {},
        });

        commit("Loading");

        if (result.data.code === 200) {
          console.log(result);
          window.location = "/apres_inscription";
        } else if (result.data.code === 422) {
          console.log(result);
          let errorMsg = "| ";
          for (let key in result.data.error) {
            errorMsg += result.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
        } else {
          commit("catchErrors", result.data.message);
        }
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
      }
    },
    Logout({ commit }) {
      commit("Loading");
      sessionStorage.clear();
      commit("Logout");
      commit("Loading");
      window.location = "/";
    },

    //Categories
    async getCategories({ commit },payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/Categories?page="+payload.page,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        store.commit("fillCategories",result.data.categories.data);
        store.commit("setcurPage",result.data.categories.current_page);
        store.commit("settotalPages",result.data.categories.last_page);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },

    async getAllCategories({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/Categories",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log("categories",result);
        store.commit("fillCategories",result.data.categories);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },


    async getSubCategories({ commit },payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/allSubCategories?page="+payload.page,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result.data.subcategories);
        store.commit("fillSubCategories",result.data.subcategories.data);
        store.commit("setcurPage",result.data.subcategories.current_page);
        store.commit("settotalPages",result.data.subcategories.last_page);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },

    async getSubCategoriesByCategory({ commit },payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/categories/"+payload.id+"/subCategories",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result);
        store.commit("fillSubCategoriesByCategory", result.data.subcategories);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },


    async getCategoriesWithSubCategories({ commit },id) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/categoriesWithSub",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result);
        store.commit("fillCategories", result.data.categories);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },
    async addCategory({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "post",
          url: store.state.urlPrefix + "/api/addCategory",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {
            name_cat_fr: payload.name_cat_fr,
            name_cat_ar: payload.name_cat_ar,
            image_url: payload.image_url,
          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");
        //store.commit("fillSellers", result.data.sellers);
        //store.state.categories.push(payload);
        if (result.data.code === 422) {
          let errorMsg = "| ";
          for (let key in result.data.error) {
            errorMsg += result.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("categoryError",errorMsg);
        }
        if (result.data.code === 200) {
          commit("catchSuccess", "La categorie a été bien ajoutée ! ");
          sessionStorage.setItem("categorySuccess","La catégorie a été bien ajoutée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("categoryError","erreur de serveur !");
        console.log(exception);
      }
    },
    async addSubCategory({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "post",
          url:
              store.state.urlPrefix + "/api/categories/" + payload.category_id + "/addsubCategory",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {
            name_scat_fr: payload.name_scat_fr,
            name_scat_ar: payload.name_cat_ar,
            image_url: payload.image_url,
            category_id: payload.category_id,
          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");
        //store.commit("fillSellers", result.data.sellers);
        //store.state.categories.push(payload);
        if (result.data.code === 422) {
          let errorMsg = "| ";
          for (let key in result.data.error) {
            errorMsg += result.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("subCategoryError",errorMsg);
        }
        if (result.data.code === 200) {
          commit("catchSuccess", "La sous catégorie a été bien ajoutée ! ");
          sessionStorage.setItem("subCategorySuccess","La sous catégorie a été bien ajoutée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("subCategoryError","erreur de serveur !");
        console.log(exception);
      }
    },
    async deleteCategories({ commit }, cat_id) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "delete",
          url: store.state.urlPrefix + "/api/deleteCategory/" + cat_id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");
        //store.commit("fillSellers", result.data.sellers);
        //store.state.categories.push(payload);
        if (result.data.code === 404) {
          commit("catchErrors", result.data.message);
          sessionStorage.setItem("categoryError",result.data.message);
        }
        if (result.data.code === 200) {
          for (let i = 0; i < store.state.categories.length; i++) {
            if (store.state.categories[i].id === cat_id) {
              store.state.categories.splice(i, 1);
            }
          }
          commit("catchSuccess", "La atégorie a été bien supprimée ! ");
          sessionStorage.setItem("categorySuccess","La catégorie a été bien supprimée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("categoryError","erreur de serveur !");
        console.log(exception);
      }
    },
    async deleteSbCategories({ commit }, scat_id) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "delete",
          url: store.state.urlPrefix + "/api/deletesubCategory/" + scat_id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");
        //store.commit("fillSellers", result.data.sellers);
        //store.state.categories.push(payload);
        if (result.data.code === 404) {
          commit("catchErrors", result.data.message);
          sessionStorage.setItem("subCategoryError",result.data.message);
        }
        if (result.data.code === 200) {
          for (let i = 0; i < store.state.subCategories.length; i++) {
            if (store.state.subCategories[i].id === scat_id) {
              store.state.subCategories.splice(i, 1);
            }
          }
          commit("catchSuccess", "La sous catégorie a été bien supprimée ! ");
          sessionStorage.setItem("subCategorySuccess","La sous catégorie a été bien supprimée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("subCategoryError","erreur de serveur !");
        console.log(exception);
      }
    },
    async getCategoryById({ commit },id) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getCategory/"+id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result.data.category);

        store.commit("fillCategoryById", result.data.category);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },
    async getSubCategoryById({ commit },id) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getSubCategory/"+id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result.data.subcategory);

        store.commit("fillSubCategoryById", result.data.subcategory);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },
    async updateCategory({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "put",
          url: store.state.urlPrefix + "/api/updateCategory/"+payload.id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {
            name_cat_fr: payload.name_cat_fr,
            name_cat_ar: payload.name_cat_ar,
            image_url: payload. image_url,

          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");

        if (result.data.code === 404) {
          let errorMsg = "| ";
          for (let key in result.data.error) {
            errorMsg += result.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("categoryError",errorMsg);
        }
        if (result.data.code === 200) {
          commit("catchSuccess", "La catégorie a été bien modifiée ! ");
          sessionStorage.setItem("categorySuccess","La catégorie a été bien modifiée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("subCategoryError","erreur de serveur !");
        console.log(exception);
      }
    },
    async updateSubCategory({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "put",
          url: store.state.urlPrefix + "/api/updateSubCategory/"+payload.id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {
            name_scat_fr: payload.name_scat_fr,
            name_scat_ar: payload.name_scat_ar,
            category_id: payload.category_id,
            image_url: payload. image_url,

          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");

        if (result.data.code === 404) {
          let errorMsg = "| ";
          for (let key in result.data.error) {
            errorMsg += result.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("subCategoryError",errorMsg);
        }
        if (result.data.code === 200) {
          commit("catchSuccess", "La sous-catégorie a été bien modifiée ! ");
          sessionStorage.setItem("subCategorySuccess","La sous catégorie a été bien modifiée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("subCategoryError","erreur de serveur !");
        console.log(exception);
      }
    },

    //Users : Admins
    async getAdmins({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/users/admins",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result);
        store.commit("fillAdmins", result.data.admins);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },
    async createAdmin({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "post",
          url: store.state.urlPrefix + "/api/addAdmin",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {
            name: payload.nom,
            email: payload.email,
            password: payload.password,
            permissions: payload.permissions
          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");
        //store.commit("fillSellers", result.data.sellers);
        //store.state.categories.push(payload);
        if (result.data.code === 422) {
          let errorMsg = "| ";
          for (let key in result.data.error) {
            errorMsg += result.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("adminError",errorMsg);
        }
        else if (result.data.code === 200) {
          commit("catchSuccess", "L'admin a été bien ajoutée ! ");
          sessionStorage.setItem("adminSuccess","L'admin' a été bien ajoutée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("subCategoryError","erreur de serveur !");
        console.log(exception);
      }
    },
    async updateAdmin({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "put",
          url: store.state.urlPrefix + "/api/updateAdmin/"+payload.admin_id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {
            name: payload.nom,
            email: payload.email,
            password: payload.password,
            permissions: payload.permissions
          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");
        //store.commit("fillSellers", result.data.sellers);
        //store.state.categories.push(payload);
        if (result.data.code === 422) {
          let errorMsg = "| ";
          for (let key in result.data.error) {
            errorMsg += result.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("adminError",errorMsg);
        }
        else if (result.data.code === 200) {
          commit("catchSuccess", "L'admin a été bien ajoutée ! ");
          sessionStorage.setItem("adminSuccess","L'admin' a été bien ajoutée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("subCategoryError","erreur de serveur !");
        console.log(exception);
      }
    },
    async deleteAdmin({ commit }, admin_id) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "delete",
          url: store.state.urlPrefix + "/api/deleteAdmin/" + admin_id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");
        //store.commit("fillSellers", result.data.sellers);
        //store.state.categories.push(payload);
        if (result.data.code === 404) {
          commit("catchErrors", result.data.message);
          sessionStorage.setItem("adminError",result.data.message);
        }
        else if (result.data.code === 200) {
          for (let i = 0; i < store.state.admins.length; i++) {
            if (store.state.admins[i].id === admin_id) {
              store.state.admins.splice(i, 1);
            }
          }
          commit("catchSuccess", "L'admin a été bien supprimée ! ");
          sessionStorage.setItem("adminSuccess","L'admin a été bien supprimée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("adminError","erreur de serveur !");
        console.log(exception);
      }
    },

    //Users : Buyers
    async getBuyersToAdd({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/buyersInscriptionDemands",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result);
        store.commit("fillBuyerstoConfirm", result.data.users);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },
    async getBuyerById({ commit }, id) {

      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getBuyer/"+id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result.data);
        store.commit("fillBuyerById", result.data.buyer);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },
    async getBuyers({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/users/buyers",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result);
        store.commit("fillBuyers", result.data.buyers);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },
    async createNewBuyer({ commit }, formData) {
      commit("Loading");
      commit("clearErrors");
      let config={
        headers:{
          "content-type":"multipart/form-data",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        }
      }
      axios.post(store.state.urlPrefix + "/api/addNewBuyer",formData,config).then(res=>{
        commit("Loading");
        commit("clearErrors");
        if (res.data.code === 200) {
          console.log("success");
          commit("catchSuccess", "L'acheteur' a été bien ajouté ! ");
          sessionStorage.setItem("buyerSuccess","L'acheteur a été bien ajoutée ! ");

        }
        else if (res.data.code === 422) {
          console.log("error");
          let errorMsg = "| ";
          for (let key in res.data.error) {
            errorMsg += res.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("buyerError",errorMsg);
        }
        else {
          commit("catchErrors", "erreur de serveur !");
          sessionStorage.setItem("buyerError","erreur de serveur !");
        }
      });
    },

    async createBuyer({ commit }, formData) {
        
      commit("Loading");
      commit("clearErrors");
      let config={
        headers:{
          "content-type":"multipart/form-data",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        }
      }
      axios.post(store.state.urlPrefix + "/api/addBuyer",formData,config).then(res=>{
        console.log("res");
        console.log(res);
        commit("Loading");
        commit("clearErrors");
        if (res.data.code === 200) {
          commit("catchSuccess", "L'acheteur a été bien ajouté ! ");
          sessionStorage.setItem("buyerSuccess","L'acheteur a été bien ajoutée ! ");

        } else if (res.data.code === 422) {
          let errorMsg = "| ";
          console.log("error",res.data.error);
          for (let key in res.data.error) {
            errorMsg += res.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("buyerError",errorMsg);
        }
        else {
          commit("catchErrors", "erreur de serveur !");
          sessionStorage.setItem("subCategoryError","erreur de serveur !");
        }
      });
    },
    async deleteBuyer({ commit }, buyer_id) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "delete",
          url: store.state.urlPrefix + "/api/deleteBuyer/" + buyer_id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");
        //store.commit("fillSellers", result.data.sellers);
        //store.state.categories.push(payload);
        if (result.data.code === 404) {
          commit("catchErrors", result.data.message);
          sessionStorage.setItem("buyerError",result.data.message);
        }
        else if (result.data.code === 200) {
          for (let i = 0; i < store.state.buyers.length; i++) {
            if (store.state.buyers[i].id === buyer_id) {
              store.state.buyers.splice(i, 1);
            }
          }
          commit("catchSuccess", "L'acheteur a été bien supprimée ! ");
          sessionStorage.setItem("buyerSuccess","L'acheteur a été bien supprimée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("buyerError","erreur de serveur !");
        console.log(exception);
      }
    },



    //Users : sellers
    async getSellersToAdd({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/sellersInscriptionDemands",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result.data.users);
        store.commit("fillSellerstoConfirm", result.data.users);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },
    async getSellerById({ commit }, id) {

      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getSeller/"+id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        store.commit("fillSellerById", result.data.seller);
       // console.log()
        console.log(result.data.seller);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },
    async getSellers({ commit }) {
      // commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/users/sellers",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        // commit("Loading");
        console.log("sellers",result);
        store.commit("fillSellers", result.data.sellers);
      } catch (exception) {
        // commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },
    async createNewSeller({ commit }, formData) {
      commit("Loading");
      commit("clearErrors");
      let config={
        headers:{
          "content-type":"multipart/form-data",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        }
      }
      axios.post(store.state.urlPrefix + "/api/addNewSeller",formData,config).then(res=>{
        commit("Loading");
        commit("clearErrors");
        if (res.data.code === 200) {
          commit("catchSuccess", "Le vendeur a été bien ajouté ! ");
          sessionStorage.setItem("sellerSuccess","Le vendeur a été bien ajoutée ! ");

        } else if (res.data.code === 422) {
          let errorMsg = "| ";
          for (let key in res.data.error) {
            errorMsg += res.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("sellerError",errorMsg);
        }
        else {
          commit("catchErrors", "erreur de serveur !");
          sessionStorage.setItem("subCategoryError","erreur de serveur !");
        }
      });
    },
    async createSeller({ commit }, formData) {
      // console.log("tantan", formData);
      commit("Loading");
      commit("clearErrors");
      let config={
        headers:{
          "content-type":"multipart/form-data",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        }
      }
      axios.post(store.state.urlPrefix + "/api/addSeller",formData,config).then(res=>{
        console.log("res");
        console.log(res);
        commit("Loading");
        commit("clearErrors");
        if (res.data.code === 200) {
          commit("catchSuccess", "Le vendeur a été bien ajouté ! ");
          sessionStorage.setItem("sellerSuccess","Le vendeur a été bien ajoutée ! ");

        } else if (res.data.code === 422) {
          let errorMsg = "| ";
          for (let key in res.data.error) {
            errorMsg += res.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("sellerError",errorMsg);
        }
        else {
          commit("catchErrors", "erreur de serveur !");
          sessionStorage.setItem("subCategoryError","erreur de serveur !");
        }
      });
    },

    async deleteSeller({ commit }, seller_id) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "delete",
          url: store.state.urlPrefix + "/api/deleteSeller/" + seller_id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");
        //store.commit("fillSellers", result.data.sellers);
        //store.state.categories.push(payload);
        if (result.data.code === 404) {
          commit("catchErrors", result.data.message);
          sessionStorage.setItem("sellerError",result.data.message);
        }
        else if (result.data.code === 200) {
          for (let i = 0; i < store.state.sellers.length; i++) {
            if (store.state.sellers[i].id === seller_id) {
              store.state.sellers.splice(i, 1);
            }
          }
          commit("catchSuccess", "Le vendeur a été bien supprimée ! ");
          sessionStorage.setItem("sellerSuccess","Le vendeur a été bien supprimée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("sellerError","erreur de serveur !");
        console.log(exception);
      }
    },



    //Products
    async createProduct({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "post",
          url: store.state.urlPrefix + "/api/addProductAd",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: payload
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");

        if (result.data.code === 422) {
          let errorMsg = "| ";
          for (let key in result.data.error) {
            errorMsg += result.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("productError",errorMsg);
        }
        if (result.data.code === 200) {
          commit("catchSuccess", "Le produit a été bien ajouté ! ");
          sessionStorage.setItem("productSuccess","Le produit a été bien ajouté ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("productError","erreur de serveur !");
        console.log(exception);
      }
    },
    async createSellerProduct({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "post",
          url: store.state.urlPrefix + "/api/addProductSeller",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: payload
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");

        if (result.data.code === 422) {
          let errorMsg = "| ";
          for (let key in result.data.error) {
            errorMsg += result.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("productError",errorMsg);
        }
        if (result.data.code === 200) {
          commit("catchSuccess", "Le produit a été bien ajouté ! ");
          sessionStorage.setItem("productSuccess","Le produit a été bien ajouté ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("productError","erreur de serveur !");
        console.log(exception);
      }
    },

    async importProduct({ commit }, payload) {
        const checkPermission = function (){
            let can = false;
            for (let i=0;i<store.state.user.permissions.length;i++){
              if (store.state.user.permissions[i].id===3){
                can = true;
              }
            }
            return can;
        };

              try {
                let result = await axios({
                  method: "post",
                  url: !checkPermission() ?  store.state.urlPrefix + "/api/importProducts/seller/"+store.state.user.id : store.state.urlPrefix + "/api/importProducts/admin/"+store.state.user.id ,
                  headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("token"),
                  },
                  data: payload,
                });
                  console.log("rowObject",payload.rowObject);
                  console.log("result",result);
                  setTimeout(function () {
                    if (result.data.response.success<result.data.response.total-result.data.response.success){
                      sessionStorage.setItem("productImportError",(result.data.response.success)+" produit a été importer de "+result.data.response.total+" produits !");
                    }
                    else{
                      sessionStorage.setItem("productImportSuccess",(result.data.response.success)+" produit a été importer de "+result.data.response.total+" produits !");
                    }
                    console.log("check",checkPermission());
                    if (!checkPermission()){
                      window.location = "/produits";
                    }
                    else {
                      window.location = "/produits/confirmes";
                    }
                  },500)
                // store.commit('Auth/clearErrors');
              }
              catch (e){
                console.log(e);
                // store.commit('Auth/catchErrors',index+" Passed Data ! invalid structure");
                // store.commit("Contacts/setImportProgress",((i+1)*100/payload.length)+"%");
                // store.commit("Contacts/addNotPassedData",payload[i]);
              }
    },

    // async importProduct({ commit }, payload) {
    //   const checkPermission = function (){
    //       let can = false;
    //       for (let i=0;i<store.state.user.permissions.length;i++){
    //         if (store.state.user.permissions[i].id===3){
    //           can = true;
    //         }
    //       }
    //       return can;
    //   };
    //   let brands = []
    //   let categoriesWithSubCat = [];
    //   try {
    //     let result = await axios({
    //       method: "get",
    //       url: store.state.urlPrefix + "/api/getConfirmedBrands",
    //       headers: {
    //         Authorization: "Bearer " + sessionStorage.getItem("token"),
    //       },
    //     });
    //     if (result.data.code === 200){
    //       brands=result.data.confirmed_brands;
    //     }
    //   } catch (exception) {
    //     brands = []
    //   }
    //
    //   try {
    //     let result = await axios({
    //       method: "get",
    //       url: store.state.urlPrefix + "/api/getCategoryWithSubCat",
    //       headers: {
    //         Authorization: "Bearer " + sessionStorage.getItem("token"),
    //       },
    //     });
    //     if (result.data.code === 200){
    //       categoriesWithSubCat=result.data.categories;
    //     }
    //   } catch (exception) {
    //     categoriesWithSubCat = []
    //   }
    //   let index = 1;
    //   console.log("xcel length",payload.rowObject.length)
    //   for (let i=0 ; i<payload.rowObject.length ; i++){
    //
    //     store.commit("setImportProgress",((i+1)*100/payload.rowObject.length).toFixed(2)+"%");
    //
    //     let brand = null;
    //     let category = null;
    //     let sub_category = null;
    //     let product_images = [];
    //
    //     for (let x=0;x<brands.length;x++){
    //       if (payload.rowObject[i]['Marque']===brands[x].brand_name){
    //         brand = brands[x];
    //       }
    //     }
    //
    //     for (let x=0;x<categoriesWithSubCat.length;x++){
    //       if (payload.rowObject[i]['Catégorie']===categoriesWithSubCat[x].name_cat_fr){
    //         category = categoriesWithSubCat[x];
    //       }
    //     }
    //     if(category!==null) {
    //       for (let j = 0; j < category.subcategories.length; j++) {
    //         if (category.subcategories[j].name_scat_fr === payload.rowObject[i]['Sous Catégorie']) {
    //           sub_category = category.subcategories[j];
    //           break;
    //         }
    //       }
    //     }
    //     if (payload.images.length>0){
    //             for (let k=0;k<payload.images.length;k++){
    //               let image_name_array = payload.images[k].name.split('.')
    //               let image_name = image_name_array[0];
    //               if (image_name_array.length>2){
    //                 for (let l=1;l<image_name_array.length-1;l++){
    //                   image_name+= "."+image_name_array[l];
    //                 }
    //               }
    //               if (payload.rowObject[i]['Référence']===image_name){
    //                 product_images.push(payload.images[k].path);
    //               }
    //             }
    //         }
    //         let data = {
    //               //reference_tawdaw: payload.rowObject[i]['Référence Taw'],
    //               reference_tawdaw: "TAWDAW-"+Math.floor(100000 + Math.random() * 900000),
    //               reference_product: payload.rowObject[i]['Référence'],
    //               name_product: payload.rowObject[i]['Titre'],
    //               description: payload.rowObject[i]['Description']??null,
    //               type_price: payload.rowObject[i]['Montant TTC']?'priced':'not_priced',
    //               qte_stock: payload.rowObject[i]['Quantité'],
    //               qte_min_vente: payload.rowObject[i]['Quantité minimale (colisage)']??null,
    //               creator_id: store.state.user.id,
    //               brand_id : brand!==null?brand.id:null,
    //               sub_category_id : sub_category?sub_category.id:null,
    //               image_principal_url: product_images.length>0?product_images[0]:null,
    //               secondary_images_url : product_images.length>1?product_images.shift():[],
    //               productCharacteristics: [],
    //               virtual_price:null,
    //               reel_price: payload.rowObject[i]['Montant TTC']??null,
    //             };
    //         try {
    //           let result = await axios({
    //             method: "post",
    //             url: !checkPermission() ?  store.state.urlPrefix + "/api/addProductSeller" : store.state.urlPrefix + "/api/addProductAd" ,
    //             headers: {
    //               Authorization: "Bearer " + sessionStorage.getItem("token"),
    //             },
    //             data: data,
    //           });
    //           if (result.data.code!==200){
    //             store.state.notPassedData = index;
    //             index++;
    //           }
    //           // store.commit('Auth/clearErrors');
    //         }
    //         catch (e){
    //           console.log(e);
    //           store.state.notPassedData = index;
    //            index++;
    //           // store.commit('Auth/catchErrors',index+" Passed Data ! invalid structure");
    //           // store.commit("Contacts/setImportProgress",((i+1)*100/payload.length)+"%");
    //           // store.commit("Contacts/addNotPassedData",payload[i]);
    //         }
    //       }
    //
    //
    //   setTimeout(function () {
    //     if (store.state.notPassedData>(payload.rowObject.length-store.state.notPassedData)){
    //       sessionStorage.setItem("productImportError",(payload.rowObject.length-store.state.notPassedData)+" produit a été importer de "+payload.rowObject.length+" produits !");
    //     }
    //     else{
    //       sessionStorage.setItem("productImportSuccess",(payload.rowObject.length-store.state.notPassedData)+" produit a été importer de "+payload.rowObject.length+" produits !");
    //     }
    //     console.log("check",checkPermission());
    //     if (!checkPermission()){
    //       window.location = "/produits";
    //     }
    //     else {
    //       window.location = "/produits/confirmes";
    //     }
    //   },500)
    // },
    async updateProduct({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "post",
          url: store.state.urlPrefix + "/api/updateProduct/"+payload.id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: payload
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");

        if (result.data.code === 422) {
          let errorMsg = "| ";
          for (let key in result.data.error) {
            errorMsg += result.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("productError",errorMsg);
        }
        if (result.data.code === 200) {
          commit("catchSuccess", "Le produit a été bien ajouté ! ");
          sessionStorage.setItem("productSuccess","Le produit a été bien modifiée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("productError","erreur de serveur !");
        console.log(exception);
      }
    },
    async getConfirmedProducts({ commit },payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getAllConfirmedProducts?page="+payload.page,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        store.commit("fillConfirmedProducts",result.data.data.data);
        store.commit("setcurPage",result.data.data.current_page);
        store.commit("settotalPages",result.data.data.last_page);

      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("productError","erreur de serveur !");
        console.log(exception);
      }
    },
    async filterConfirmedProducts({ commit },payload) {
      console.log("filter");
      commit("Loading");
      commit("clearErrors");
      console.log("url",store.state.urlPrefix + "/api/filterConfirmedProducts?page="+payload.page+(payload.creator_id && payload.creator_id!==""?"&&creator_id="+payload.creator_id:"")
          +(payload.sub_category_id && payload.sub_category_id!==""?"&&sub_category_id="+payload.sub_category_id:"")
          +(payload.reference_product && payload.reference_product!==""?"&&reference_product="+payload.reference_product:""));
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/filterConfirmedProducts?page="+payload.page+(payload.creator_id && payload.creator_id!==""?"&&creator_id="+payload.creator_id:"")
          +(payload.sub_category_id && payload.sub_category_id!==""?"&&sub_category_id="+payload.sub_category_id:"")
          +(payload.reference_product && payload.reference_product!==""?"&&reference_product="+payload.reference_product:""),
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        store.commit("fillConfirmedProducts",result.data.data.data);
        store.commit("setcurPage",result.data.data.current_page);
        store.commit("settotalPages",result.data.data.last_page);

      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("productError","erreur de serveur !");
        console.log(exception);
      }
    },

    async filterUnconfirmedProducts({ commit },payload) {
      console.log("filter");
      commit("Loading");
      commit("clearErrors");
      console.log("url",store.state.urlPrefix + "/api/filterUnconfirmedProducts?page="+payload.page+(payload.creator_id && payload.creator_id!==null?"&&creator_id="+payload.creator_id:"")
          +(payload.sub_category_id && payload.sub_category_id!==""?"&&sub_category_id="+payload.sub_category_id:"")
          +(payload.reference_product && payload.reference_product!==""?"&&reference_product="+payload.reference_product:""));
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/filterUnconfirmedProducts?page="+payload.page+(payload.creator_id && payload.creator_id!==""?"&&creator_id="+payload.creator_id:"")
              +(payload.sub_category_id && payload.sub_category_id!==""?"&&sub_category_id="+payload.sub_category_id:"")
              +(payload.reference_product && payload.reference_product!==""?"&&reference_product="+payload.reference_product:""),
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        store.commit("fillUnconfirmedProducts",result.data.data.data);
        store.commit("setcurPage",result.data.data.current_page);
        store.commit("settotalPages",result.data.data.last_page);

      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("productError","erreur de serveur !");
        console.log(exception);
      }
    },

    async getUnconfirmedProducts({ commit },payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getAllUnconfirmedProducts?page="+payload.page,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result);
        store.commit("fillUnconfirmedProducts",result.data.data.data);
        store.commit("setcurPage",result.data.data.current_page);
        store.commit("settotalPages",result.data.data.last_page);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("productError","erreur de serveur !");
        console.log(exception);
      }
    },
    async deleteProduct({ commit }, product_id) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "delete",
          url: store.state.urlPrefix + "/api/deleteProduct/"+product_id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");
        //store.commit("fillSellers", result.data.sellers);
        //store.state.categories.push(payload);
        if (result.data.code === 404) {
          commit("catchErrors", result.data.message);
          sessionStorage.setItem("productError",result.data.message);
        }
        if (result.data.code === 200) {
          for (let i = 0; i < store.state.confirmedProducts.length; i++) {
            if (store.state.confirmedProducts[i].id === product_id) {
              store.state.confirmedProducts.splice(i, 1);
            }
          }
          commit("catchSuccess", "Le produit a été bien supprimée ! ");
          sessionStorage.setItem("productSuccess","Le produit a été bien supprimée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("productError","erreur de serveur !");
        console.log(exception);
      }
    },
    async deleteBulkProduct({ commit },payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "delete",
          url: store.state.urlPrefix + "/api/deleteBulkProduct",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {products_id : payload.products_id}
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");
        //store.commit("fillSellers", result.data.sellers);
        //store.state.categories.push(payload);
        if (result.data.code === 404) {
          commit("catchErrors", result.data.message);
          sessionStorage.setItem("productError",result.data.message);
        }
        if (result.data.code === 200) {
          // for (let i = 0; i < store.state.confirmedProducts.length; i++) {
          //   if (products_id.includes(store.state.confirmedProducts[i].id)) {
          //     console.log("includ product :",store.state.confirmedProducts[i].id)
          //     store.state.confirmedProducts.splice(i, 1);
          //   }
          // }
          commit("catchSuccess", "Les produits ont bien supprimée ! ");
          store.dispatch("filterConfirmedProducts",{page:(1),reference_product:payload.reference_product,
            sub_category_id:payload.sub_category_id,creator_id:payload.vendor_id});
          sessionStorage.setItem("productSuccess","Le produit ont bien supprimée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("productError","erreur de serveur !");
        console.log(exception);
      }
    },

    async getProductById({ commit },id) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getConfirmedProductById/"+id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result.data.product);

        store.commit("fillProductById", result.data.product);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },



    //Brands
    async getBrands({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getConfirmedBrands",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result);
        store.commit("fillConfirmedBrands", result.data.confirmed_brands);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },
    async getBrandByName({ commit },name) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getBrandByName/"+name,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
        if (result.data.code === 200){
          return result.data.brand;
        }
        else {
          return null;
        }
      } catch (exception) {
        return null;
      }
    },
    async getPendingBrands({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getBrandsPending",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result.data.pending_brands);

        store.commit("fillPendingBrands", result.data.pending_brands);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },
    async getConfirmedBrands({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getConfirmedBrands",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result.data.confirmed_brands);

        store.commit("fillConfirmedBrands", result.data.confirmed_brands);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },
    async createBrand({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "post",
          url: store.state.urlPrefix + "/api/addBrandAd",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {
            brand_name: payload.brand_name,
            image_url: payload.image_url,
          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");

        if (result.data.code === 422) {
          let errorMsg = "| ";
          for (let key in result.data.error) {
            errorMsg += result.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("brandError",errorMsg);
        }
        if (result.data.code === 200) {
          commit("catchSuccess", "La marque a été bien ajoutée ! ");
          sessionStorage.setItem("brandSuccess","La marque a été bien ajoutée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("brandError","erreur de serveur !");
        console.log(exception);
      }
    },
    async getbrandById({ commit },id) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getConfimedBrandById/"+id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result.data.brand);

        store.commit("fillBrandById", result.data.brand);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },
    async updateBrand({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "put",
          url: store.state.urlPrefix + "/api/updateBrand/"+payload.id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {
            brand_name: payload.brand_name,

            image_url: payload. image_url,

          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");

        if (result.data.code === 404) {
          let errorMsg = "| ";
          for (let key in result.data.error) {
            errorMsg += result.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("brandError",errorMsg);
        }
        if (result.data.code === 200) {
          commit("catchSuccess", "La marque a été bien modifiée ! ");
          sessionStorage.setItem("brandSuccess","La marque a été bien modifiée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("brandError","erreur de serveur !");
        console.log(exception);
      }
    },
    async deleteBrand({ commit }, id) {
      commit("Loading");
      commit("clearErrors");
      try {
        console.log(id);
        let result = await axios({
          method: "delete",
          url: store.state.urlPrefix + "/api/deleteBrand/" + id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");
        // store.commit("fillSellers", result.data.sellers);
        // store.state.categories.push(payload);
        if (result.data.code === 422) {
          let errorMsg = "| ";
          for (let key in result.data.error) {
            errorMsg += result.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("brandError",errorMsg);
        }
        if (result.data.code === 200) {
          for (let i = 0; i < store.state.confirmedbrands.length; i++) {
            if (store.state.confirmedbrands[i].id === id) {
              store.state.confirmedbrands.splice(i, 1);
            }
          }
          commit("catchSuccess", "La atégorie a été bien supprimée ! ");
          sessionStorage.setItem("brandSuccess","La marque a été bien supprimée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("brandError","erreur de serveur !");
        console.log(exception);
      }
    },


    //Product characteristics
    async getCharacteristics({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getCharacteristics",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result);
        store.commit("fillCharacteristics", result.data.chracteristics);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },


    //Statistiques
    async getTotalStatistics({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getStatistics",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result.data.StatisticsData);
        // if (result.data.StatisticsData[0].total_sales===null){
        //   store.commit("fillTotalStatistics", 0);
        // }else{
          store.commit("fillTotalStatistics", result.data.StatisticsData);
        // }
        // store.commit("fillTotalSales", result.data.totalSales[0].total_sales);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },

    async getTotalOwnStatistics({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getOwnStatistics/"+store.state.user.id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result.data.StatisticsData);
        // if (result.data.StatisticsData[0].total_sales===null){
        //   store.commit("fillTotalStatistics", 0);
        // }else{
        store.commit("fillTotalOwnStatistics", result.data.StatisticsData);
        // }
        // store.commit("fillTotalSales", result.data.totalSales[0].total_sales);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },


    async TotalSalesPerMonth({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/monthlyTotalSales",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        console.log(result.data.totalBuyers[0].total_buyers);
        store.commit("fillTotalBuyers", result.data.totalBuyers[0].total_buyers);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },
    async getTotalSalesPerMonth({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/totalSalesPerMonth",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        // console.log(result.data.totalSalesPerMonth);

        console.log("totale",result.data.totalSalesPerMonth);
        store.commit("fillSalesPerMonth", result.data.totalSalesPerMonth);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },
    async getTotalSellerSalesPerMonth({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/totalSellerSalesPerMonth/"+store.state.user.id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        // console.log(result.data.totalSalesPerMonth);

        console.log("totale",result.data.totalSalesPerMonth);
        store.commit("fillSalesPerMonth", result.data.totalSalesPerMonth);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },

    async getCountCommandsPerDeliveryMode({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/countCommandsPerDeliveryMode",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        // console.log(result.data.totalSalesPerMonth);

        console.log("totale",result.data.countCommandsPerDeliveryMode);
        store.commit("fillCountCommandsPerDeliveryMode", result.data.countCommandsPerDeliveryMode);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },

    async getCountSellerCommandsPerDeliveryMode({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/countSellerCommandsPerDeliveryMode/"+store.state.user.id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        commit("Loading");
        // console.log(result.data.totalSalesPerMonth);

        console.log("totale",result.data.countCommandsPerDeliveryMode);
        store.commit("fillCountSellerCommandsPerDeliveryMode", result.data.countCommandsPerDeliveryMode);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },





    async RefuseBuyer({ commit },payload) {
    commit("clearErrors");
    try {
      let result = await axios({
        method: "post",
        url: store.state.urlPrefix + "/api/RefuseBuyer/" + payload.id,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
        data: {
          reason: payload.reason,
          
        },
      });

      commit("clearErrors");
    
      if (result.data.code === 422) {
        store.state.rejectedSuccess=false; 
        let errorMsg = "| ";
        for (let key in result.data.error) {
          errorMsg += result.data.error[key];
          errorMsg += " | ";
        }
        commit("catchErrors", errorMsg);
                

      }
      if (result.data.code === 200) {
        store.state.rejectedSuccess=true; 
        for (let i = 0; i < store.state.buyerstoConfirm.length; i++) {
          if (store.state.buyerstoConfirm[i].id === payload.id) {
            store.state.buyerstoConfirm.splice(i, 1);
          }
        }
        commit("catchSuccess", "Le acheteur a été bien refusé ! ");
                sessionStorage.setItem("buyerSuccess","L'acheteur a été bien refusé ! ");

      }
    } catch (exception) {
      commit("Loading");
      commit("clearSuccess");
      commit("catchErrors", "erreur de serveur !");
      console.log(exception);
    }
  },










  async RefuseSeller({ commit },payload) {
    commit("clearErrors");
    try {
    
      let result = await axios({
        method: "post",
        url: store.state.urlPrefix + "/api/RefuseSeller/" + payload.id,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
        data: {
          reason: payload.reason,
          
        },
      });
      commit("clearErrors");
    
      if (result.data.code === 422) {
        store.state.rejectedSuccess=false; 
        let errorMsg = "| ";
        for (let key in result.data.error) {
          errorMsg += result.data.error[key];
          errorMsg += " | ";
        }
        commit("catchErrors", errorMsg);
      }
      if (result.data.code === 200) {
        store.state.rejectedSuccess=true; 
        for (let i = 0; i < store.state.sellerstoConfirm.length; i++) {
          if (store.state.sellerstoConfirm[i].id === payload.id) {
            store.state.sellerstoConfirm.splice(i, 1);
          }
        }
        commit("catchSuccess", "Le vendeur a été bien refusé ! ");
        sessionStorage.setItem("sellerSuccess","Le vendeur a été bien refusé ! ");

      }
    } catch (exception) {
      commit("Loading");
      commit("clearSuccess");
      commit("catchErrors", "erreur de serveur !");
      console.log(exception);
    }
  },


  async ConfirmProduct({ commit },payload) {
   commit("clearErrors");
    try {
      let result = await axios({
        method: "get",
        url: store.state.urlPrefix + "/api/confirmProduct/" + payload.id,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
    
      });

      commit("clearErrors");
    
      if (result.data.code === 200) {
         
        commit("catchSuccess", "Le produit a été bien confirmé ! ");
                sessionStorage.setItem("productSuccess","Le produit a été bien confirmé ! ");

      }
    } catch (exception) {
      commit("Loading");
      commit("clearSuccess");
      commit("catchErrors", "erreur de serveur !");
      console.log(exception);
    }
  },

    async ConfirmBulkProduct({ commit },payload) {
      commit("clearErrors");
      try {
        let result = await axios({
          method: "post",
          url: store.state.urlPrefix + "/api/confirmBulkProduct",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data : {products_id : payload.products_id}
        });

        commit("clearErrors");

        if (result.data.code === 200) {

          commit("catchSuccess", "Les produits ont bien confirmé ! ");
          sessionStorage.setItem("productSuccess","Les produits ont bien confirmé ! ");
          window.location = "/produits/confirmes";
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },






    async RefuseProduct({ commit },payload) {
     commit("clearErrors");
    try {
      let result = await axios({
        method: "get",
        url: store.state.urlPrefix + "/api/RefuseProduct/" + payload.id,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
        // data: {
        //   reason: payload.reason,
          
        // },
      });

      commit("clearErrors");
    
      if (result.data.code === 200) {
         
        commit("catchSuccess", "Le produit a été bien refusée ! ");
                sessionStorage.setItem("productSuccess","Le produit a été bien refusé ! ");

      }
    } catch (exception) {
      commit("Loading");
      commit("clearSuccess");
      commit("catchErrors", "erreur de serveur !");
      console.log(exception);
    }
  },

    async BulkRefuseProduct({ commit },payload) {
      commit("clearErrors");
      try {
        let result = await axios({
          method: "post",
          url: store.state.urlPrefix + "/api/RefuseBulkProduct",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {
            products_id: payload.products_id,

          },
        });

        commit("clearErrors");

        if (result.data.code === 200) {

          commit("catchSuccess", "Les produits ont bien refusée ! ");
          sessionStorage.setItem("productSuccess","Les produits ont bien refusé ! ");
          window.location = "/produits/confirmes";

        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("productError","Erreur de serveur");
        console.log(exception);
      }
    },



  async RefuseBrand({ commit },payload) {
    commit("clearErrors");
   try {
     let result = await axios({
       method: "get",
       url: store.state.urlPrefix + "/api/RefuseBrand/" + payload.id,
       headers: {
         Authorization: "Bearer " + sessionStorage.getItem("token"),
       },
       // data: {
       //   reason: payload.reason,
         
       // },
     });

     commit("clearErrors");
   
     if (result.data.code === 404) {
       
      commit("catchErrors", "La marque n'est pas trouvée ! ");
              sessionStorage.setItem("brandError","La marque n'est pas trouvée ! ");

    }
     if (result.data.code === 200) {
        
       commit("catchSuccess", "La marque a été bien refusée ! ");
               sessionStorage.setItem("brandSuccess","La marque a été bien refusée ! ");

     }
   } catch (exception) {
     commit("Loading");
     commit("clearSuccess");
     commit("catchErrors", "erreur de serveur !");
     console.log(exception);
   }
 },





 async ConfirmBrand({ commit },payload) {
  commit("clearErrors");
  try {
    let result = await axios({
      method: "get",
      url: store.state.urlPrefix + "/api/ConfirmBrand/" + payload.id,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
  
    });
    commit("clearErrors");
    if (result.data.code === 404) {
       
      commit("catchErrors", "La marque n'est pas trouvée ! ");
              sessionStorage.setItem("brandError","La marque n'est pas trouvée ! ");
    }
  
    if (result.data.code === 200) {   
      commit("catchSuccess", "La marque a été bien confirmée ! ");
              sessionStorage.setItem("brandSuccess","La marque a été bien confirmée ! ");
    }
  } catch (exception) {
    commit("Loading");
    commit("clearSuccess");
    commit("catchErrors", "erreur de serveur !");
    console.log(exception);
  }
},




async UpdateBuyer({ commit }, formData) {

  commit("Loading");
  commit("clearErrors");
  
  let config={
    headers:{
      "content-type":"multipart/form-data",
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    }
  }
 
  axios.post(store.state.urlPrefix + "/api/updateBuyer",formData,config).then(res=>{
    console.log("rasta");
    console.log(res);
    commit("Loading");
    commit("clearErrors");
    if (res.data.code === 200) {
      commit("catchSuccess", "L'acheteur a été bien modifié ! ");
      sessionStorage.setItem("buyerSuccess","L'acheteur a été bien modifié ! ");

    } else if (res.data.code === 422) {
      let errorMsg = "| ";
      for (let key in res.data.error) {
        errorMsg += res.data.error[key];
        errorMsg += " | ";
      }
      commit("catchErrors", errorMsg);
      sessionStorage.setItem("buyerError",errorMsg);
    }
    else {
      commit("catchErrors", "erreur de serveur !");
      sessionStorage.setItem("buyerError","erreur de serveur !");
    }
  });
},






async UpdateSeller({ commit }, formData) {

  commit("Loading");
  commit("clearErrors");
  
  let config={
    headers:{
      "content-type":"multipart/form-data",
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    }
  }
 
  axios.post(store.state.urlPrefix + "/api/updateSeller",formData,config).then(res=>{
    console.log("rasta");
    console.log(res);
    commit("Loading");
    commit("clearErrors");
    if (res.data.code === 200) {
      commit("catchSuccess", "Le vendeur a été bien modifié ! ");
      sessionStorage.setItem("sellerSuccess","Le vendeur a été bien modifié ! ");

    } else if (res.data.code === 422) {
      let errorMsg = "| ";
      for (let key in res.data.error) {
        errorMsg += res.data.error[key];
        errorMsg += " | ";
      }
      commit("catchErrors", errorMsg);
      sessionStorage.setItem("sellerError",errorMsg);
    }
    else {
      commit("catchErrors", "erreur de serveur !");
      sessionStorage.setItem("sellerError","erreur de serveur !");
    }
  });
},
async getAdminById({ commit },id) {
  commit("Loading");
  commit("clearErrors");
  try {
    let result = await axios({
      method: "get",
      url: store.state.urlPrefix + "/api/getAdminById/"+id,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });

    commit("Loading");
    console.log(result.data.admin);

    store.commit("fillAdminById", result.data.admin);
  } catch (exception) {
    commit("Loading");
    commit("catchErrors", "erreur de serveur !");
    console.log(exception);
  }
},


 

async getSellerProducts({ commit },payload) {
  commit("Loading");
  commit("clearErrors");
  try {
    let result = await axios({
      method: "get",
      url: store.state.urlPrefix + "/api/getSellerProducts/"+store.state.user.id+"?page="+payload.page,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });

    commit("Loading");
    console.log("products",result);

    // console.log("seller products",result.data.products_seller[0].media);
    store.commit("fillSellerProducts",result.data.products_seller.data);
    store.commit("setcurPage",result.data.products_seller.current_page);
    store.commit("settotalPages",result.data.products_seller.last_page);
  } catch (exception) {
    commit("Loading");
    commit("catchErrors", "erreur de serveur !");
    sessionStorage.setItem("productError","erreur de serveur !");
    console.log(exception);
  }
},


async deleteSellerManager({ commit }, manager_id) {
  commit("Loading");
  commit("clearErrors");
  try {
    let result = await axios({
      method: "delete",
      url: store.state.urlPrefix + "/api/deleteSellerManager/" + manager_id,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
    console.log(result);
    commit("Loading");
    commit("clearErrors");
   
    if (result.data.code === 404) {
      commit("catchErrors", result.data.message);
      sessionStorage.setItem("sellermanagerError",result.data.message);
    }
    else if (result.data.code === 200) {
      for (let i = 0; i < store.state.sellermanagers.length; i++) {
        if (store.state.sellermanagers[i].id === manager_id) {
          store.state.sellermanagers.splice(i, 1);
        }
      }
      commit("catchSuccess", "Le manager a été bien supprimé ! ");
      sessionStorage.setItem("sellermanagerSuccess","Le manager a été bien supprimé ! ");
    }
  } catch (exception) {
    commit("Loading");
    commit("clearSuccess");
    commit("catchErrors", "erreur de serveur !");
    sessionStorage.setItem("sellermanagerError","erreur de serveur !");
    console.log(exception);
  }
},

async getSelleManagers({ commit }, id) {
  commit("Loading");
  commit("clearErrors");
  try {
    let result = await axios({
      method: "get",
      url: store.state.urlPrefix + "/api/getSellerManagers/"+id,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });

    commit("Loading");
    console.log("seller manager",result.data);
    store.commit("fillSellermanagers",result.data.seller_managers);
  } catch (exception) {
    commit("Loading");
    commit("catchErrors", "erreur de serveur !");
    sessionStorage.setItem("sellermanagerError","erreur de serveur !");
    console.log(exception);
  }
},



async getSellerManagerById({ commit },id) {
  commit("Loading");
  commit("clearErrors");

  try {
    let result = await axios({
      method: "get",
      url: store.state.urlPrefix + "/api/getSellermanagerById/"+id,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });

    commit("Loading");
    console.log('saloua',result.data);

    store.commit("fillSellerManagerById", result.data.seller_manager);
  } catch (exception) {
    commit("Loading");
    commit("catchErrors", "erreur de serveur !");
    console.log(exception);
  }
},



async updateSellerManager({ commit }, payload) {
  commit("Loading");
  commit("clearErrors");
  console.log('idd', payload.id)
  try {
    let result = await axios({
      method: "put",
      url: store.state.urlPrefix + "/api/updateSellerManager/"+payload.id,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
      data: {
        name: payload.name,
        email: payload.email,
        password: payload. password,
        permissions : payload.permissions

      },
    });
    console.log(result);
    commit("Loading");
    commit("clearErrors");

    if (result.data.code === 404) {
      let errorMsg = "| ";
      for (let key in result.data.error) {
        errorMsg += result.data.error[key];
        errorMsg += " | ";
      }
      commit("catchErrors", errorMsg);
      sessionStorage.setItem("sellermanagerError",errorMsg);
    }
    if (result.data.code === 200) {
      commit("catchSuccess", "Le manager a été bien modifié ! ");
      sessionStorage.setItem("sellermanagerSuccess","Le manager a été bien modifié ! ");
    }else if (result.data.code === 422) {
      let errorMsg = "| ";
      for (let key in result.data.error) {
        errorMsg += result.data.error[key];
        errorMsg += " | ";
      }
      commit("catchErrors", errorMsg);
      sessionStorage.setItem("sellermanagerError",errorMsg);
    }
  } catch (exception) {
    commit("Loading");
    commit("clearSuccess");
    commit("catchErrors", "erreur de serveur !");
    sessionStorage.setItem("sellermanagerError","erreur de serveur !");
    console.log(exception);
  }
},
    async createSellerManager({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try {
        //console.log()
        let result = await axios({
          method: "post",
          url: store.state.urlPrefix + "/api/addSellManager",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {
            name: payload.name,
            email: payload.email,
            password: payload.password,
            permissions: payload.permissions,
            user_id : store.state.user.id
          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");

        if (result.data.code === 422) {
          let errorMsg = "| ";
          for (let key in result.data.error) {
            errorMsg += result.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("sellermanagerError",errorMsg);
        }
        else if (result.data.code === 200) {
          commit("catchSuccess", "Le manager a été bien ajoutée ! ");
          sessionStorage.setItem("sellermanagerSuccess","Le manager a été bien ajoutée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("sellermanagerError","erreur de serveur !");
        console.log(exception);
      }
    },

    async getAdminDevis({ commit }, id) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getAdminDevis/"+id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
    
        commit("Loading");
        console.log("admin devis",result.data);
        store.commit("fillAdminDevis",result.data.devis_admin);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("admindevisError","erreur de serveur !");
        console.log(exception);
      }
    },
    async getSellerDevis({ commit }, id) {
      commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getSellerDevis/"+id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
    
        commit("Loading");
        console.log("admin devis",result.data);
        store.commit("fillSellerDevis",result.data.devis_seller);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("admindevisError","erreur de serveur !");
        console.log(exception);
      }
    },

    async getConfirmedSellerCommand({ commit }, id) {
      commit("Loading");
      commit("clearErrors");
      try { 
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getSellerConfirmedCommands/"+id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
    
        commit("Loading");
        console.log("seller command",result.data);
        store.commit("fillConfirmedSellerCommand",result.data.commands);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("confirmedsellercommandError","erreur de serveur !");
        console.log(exception);
      }
    },
    
    async getPendingSellerCommand({ commit }, id) {
      commit("Loading");
      commit("clearErrors");
      try { 
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getSellerPendingCommands/"+id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
    
        commit("Loading");
        console.log("seller command",result.data);
        store.commit("fillPendingSellerCommand",result.data.commands);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("pendingsellercommandError","erreur de serveur !");
        console.log(exception);
      }
    },


    async getConfirmedAdminCommand({ commit }, id) {
      commit("Loading");
      commit("clearErrors");
      try { 
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getAdminConfirmedCommands/"+id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
    
        commit("Loading");
        console.log("admin command",result.data);
        store.commit("fillConfirmedAdminCommand",result.data.commands);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("confirmedadmincommandError","erreur de serveur !");
        console.log(exception);
      }
    },
    async getPendingAdminCommand({ commit }, id) {
      commit("Loading");
      commit("clearErrors");
      try { 
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getAdminPendingCommands/"+id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
    
        commit("Loading");
        console.log("admin command",result.data);
        store.commit("fillPendingAdminCommand",result.data.commands);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("pendingadmincommandError","erreur de serveur !");
        console.log(exception);
      }
    },
    async getConfirmedSellerCommandById({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try { 
        console.log(payload)
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getSellerCommandById/"+payload.id_cmd+"/"+payload.user_id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
    
        commit("Loading");
        console.log(" command",result.data);
        store.commit("fillConfirmedSellerCommandById",result.data.command);
      
    } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("confirmedSellerCommandByIdError","erreur de serveur !");
        console.log(exception);
      }
    },

    async getConfirmedAdminCommandById({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try { 
        console.log(payload)
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getAdminCommandById/"+payload.id_cmd+"/"+payload.user_id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
    
        commit("Loading");
        console.log(" command",result.data);
        store.commit("fillConfirmedAdminCommandById",result.data.command);
      
    } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("confirmedSellerCommandByIdError","erreur de serveur !");
        console.log(exception);
      }
    },

    async getProductWithoutPriceByDevisId({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      try { 
        console.log('this is payload yy')
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getProductWithoutPriceByDevisId",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          params: {
           ids:payload.ids,
            
          },
        });
    
        commit("Loading");
        console.log(" products",result.data);
        store.commit("fillProductWithoutPriceByDevisId",result.data.products);
      
    } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("ProductWithoutPriceByDevisIdError","erreur de serveur !");
        console.log(exception);
      }
    },


    async getDeliveryModes({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try { 
   
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getDeliveryModes",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        
        });
    
        commit("Loading");
        console.log(" delivery modes",result.data);
        store.commit("fillDeliveryModes",result.data.Delivery_modes);
      
    } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("deliverymodesError","erreur de serveur !");
        console.log(exception);
      }
    },



    async getPaymentModes({ commit }) {
      commit("Loading");
      commit("clearErrors");
      try { 
     
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getPaymentModes",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        
        });
    
        commit("Loading");
        console.log(" payment modes",result.data);
        store.commit("fillPaymentModes",result.data.Payment_modes);
      
    } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("paymentmodesError","erreur de serveur !");
        console.log(exception);
      }
    },

    async addSellerCommand({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      console.log('this is ooooo',payload)
      try {
        let result = await axios({
          method: "post",
          url: store.state.urlPrefix + "/api/addCommand",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {
            delivery_mode_id: payload.delivery_mode_id,
            payment_mode_id: payload.payment_mode_id,
            buyer_id: payload.buyer_id,
            non_priced_products: payload.non_priced_products,
            DevisIds:  payload.DevisIds
          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");
       
        if (result.data.code === 422) {
          let errorMsg = "| ";
          for (let key in result.data.error) {
            errorMsg += result.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("commandsellerError",errorMsg);
        }
        if (result.data.code === 200) {
          commit("catchSuccess", "La commande a été bien ajoutée ! ");
          sessionStorage.setItem("commandsellerSuccess","La commande a été bien ajoutée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("commandsellerError","erreur de serveur !");
        console.log(exception);
      }
    },
    async addAdminCommand({ commit }, payload) {
      commit("Loading");
      commit("clearErrors");
      console.log('this is ooooo',payload)
      try {
        let result = await axios({
          method: "post",
          url: store.state.urlPrefix + "/api/addCommand",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {
            delivery_mode_id: payload.delivery_mode_id,
            payment_mode_id: payload.payment_mode_id,
            buyer_id: payload.buyer_id,
            non_priced_products: payload.non_priced_products,
            DevisIds:  payload.DevisIds
          },
        });
        console.log(result);
        commit("Loading");
        commit("clearErrors");
       
        if (result.data.code === 422) {
          let errorMsg = "| ";
          for (let key in result.data.error) {
            errorMsg += result.data.error[key];
            errorMsg += " | ";
          }
          commit("catchErrors", errorMsg);
          sessionStorage.setItem("commandadminError",errorMsg);
        }
        if (result.data.code === 200) {
          commit("catchSuccess", "La commande a été bien ajoutée ! ");
          sessionStorage.setItem("commandadminSuccess","La commande a été bien ajoutée ! ");
        }
      } catch (exception) {
        commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("commandadminError","erreur de serveur !");
        console.log(exception);
      }
    },




    async ConfirmAdminCommand({ commit },id_cmd) {
      commit("clearErrors");
       try {
         let result = await axios({
           method: "get",
           url: store.state.urlPrefix + "/api/ConfirmAdminCommand/" + id_cmd+"/"+store.state.user.id,
           headers: {
             Authorization: "Bearer " + sessionStorage.getItem("token"),
           },
       
         });
   
         commit("clearErrors");
       
         if (result.data.code === 200) {
            
           commit("catchSuccess", "La commande a été bien confirmée ! ");
                   sessionStorage.setItem("pendingadmincommandsSuccess","La commande a été bien confirmée ! ");
   
         }
         if (result.data.code === 404) {
          commit("catchErrors", result.data.message);
          sessionStorage.setItem("pendingadmincommandsError",result.data.message);
        }
         
       } catch (exception) {
         commit("Loading");
         commit("clearSuccess");
         commit("catchErrors", "erreur de serveur !");
         console.log(exception);
       }
     },




     async ConfirmSellerCommand({ commit },id_cmd) {
      commit("clearErrors");
       try {
         let result = await axios({
           method: "get",
           url: store.state.urlPrefix + "/api/ConfirmSellerCommand/" + id_cmd+"/"+store.state.user.id,
           headers: {
             Authorization: "Bearer " + sessionStorage.getItem("token"),
           },
       
         });
        //  commit("Loading");
         console.log("cmd_resume",result.data.cmd_resume);
         store.commit("fillcmdResume",result.data.cmd_resume);
        //  commit("clearErrors");
       
         if (result.data.code === 200) {
            
           commit("catchSuccess", "La commande a été bien confirmée ! ");
                   sessionStorage.setItem("pendingsellercommandsSuccess","La commande a été bien confirmée ! ");
   
         }
         if (result.data.code === 404) {
          commit("catchErrors", result.data.message);
          sessionStorage.setItem("pendingsellercommandsError",result.data.message);
        }
         
       } catch (exception) {
         commit("Loading");
         commit("clearSuccess");
         commit("catchErrors", "erreur de serveur !");
         console.log(exception);
       }
     },





     async getSearchedProducts({ commit }, payload) {
     // commit("Loading");
      commit("clearErrors");
      console.log(payload)
      try {
        let result = await axios({
          method: "post",
          url:
              store.state.urlPrefix + "/api/searchProduct",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {
            searched: payload.searched,
            subCategory_id : payload.subCategory_id
          },
        });
        // console.log('the searched products',result);
      //  commit("Loading");
        commit("clearErrors");
        // store.commit("fillSellers", result.data.searched_products);
        // store.state.categories.push(payload);
       
        if (result.data.code === 200) {
          store.commit("fillsearchedProducts",result.data.searched_products);
          commit("catchSuccess", "La sous catégorie a été bien ajoutée ! ");
          sessionStorage.setItem("subCategorySuccess","La sous catégorie a été bien ajoutée ! ");
        }
      } catch (exception) {
       // commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("subCategoryError","erreur de serveur !");
        console.log(exception);
      }
    },


    async updatePromotedProducts({ commit }, payload) {
      //commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "post",
          url:
              store.state.urlPrefix + "/api/updatePromotedProductsList",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {
            products_id: payload.products_id,
          },
        });
        // console.log('the searched products',result);
       // commit("Loading");
        commit("clearErrors");
        // store.commit("fillSellers", result.data.searched_products);
        // store.state.categories.push(payload);
        if (result.data.code === 200) {
          store.commit("fillpromotedProducts",result.data.promoted_products);
          commit("catchSuccess", "Les produits de promotion sont bient ajoutée! ");
          sessionStorage.setItem("promotedProductsSuccess","Les produits de promotion sont bient ajoutée!");
        }
      } catch (exception) {
       // commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("promotedProductsError","erreur de serveur !");
        console.log(exception);
      }
    },
    async getPromotedProducts({ commit }, payload) {
      // commit("Loading");
      commit("clearErrors");
      try {
        let result = await axios({
          method: "get",
          url:
              store.state.urlPrefix + "/api/promotedProducts",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
        // console.log('the searched products',result);
        //  commit("Loading");
        commit("clearErrors");
        // store.commit("fillSellers", result.data.searched_products);
        // store.state.categories.push(payload);

        if (result.data.code === 200) {
          store.commit("fillpromotedProducts",result.data.promoted_products);
         // commit("catchSuccess", "Les produits de promotion sont bient ajoutée! ");
         // sessionStorage.setItem("promotedProductsSuccess","Les produits de promotion sont bient ajoutée!");
        }
      } catch (exception) {
        // commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("promotedProductsError","erreur de serveur !");
        console.log(exception);
      }
    },



async refuseSellerCommand({ commit },id_cmd) {
      commit("clearErrors");
       try {
         let result = await axios({
           method: "get",
           url: store.state.urlPrefix + "/api/refuseSellerCommand/" + id_cmd+"/"+store.state.user.id,
           headers: {
             Authorization: "Bearer " + sessionStorage.getItem("token"),
           },
       
         });
         if (result.data.code === 200) {
            
           commit("catchSuccess", "La commande a été bien refusée ! ");
                   sessionStorage.setItem("pendingsellercommandsSuccess","La commande a été bien refusée ! ");
   
         }
         if (result.data.code === 404) {
          commit("catchErrors", result.data.message);
          sessionStorage.setItem("pendingsellercommandsError",result.data.message);
        }
         
       } catch (exception) {
         commit("Loading");
         commit("clearSuccess");
         commit("catchErrors", "erreur de serveur !");
         console.log(exception);
       }
     },




     async refuseAdminCommand({ commit },id_cmd) {
      commit("clearErrors");
       try {
         let result = await axios({
           method: "get",
           url: store.state.urlPrefix + "/api/refuseAdminCommand/" + id_cmd+"/"+store.state.user.id,
           headers: {
             Authorization: "Bearer " + sessionStorage.getItem("token"),
           },
       
         });
         if (result.data.code === 200) {
            
           commit("catchSuccess", "La commande a été bien refusée ! ");
                   sessionStorage.setItem("pendingadmincommandsSuccess","La commande a été bien refusée ! ");
   
         }
         if (result.data.code === 404) {
          commit("catchErrors", result.data.message);
          sessionStorage.setItem("pendingadmincommandsError",result.data.message);
        }
         
       } catch (exception) {
         commit("Loading");
         commit("clearSuccess");
         commit("catchErrors", "erreur de serveur !");
         console.log(exception);
       }
     },

     async getSellerUnConfirmedCommands({ commit }, id) {
      commit("Loading");
      commit("clearErrors");
      try { 
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getSellerUnConfirmedCommands/"+id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
    
        commit("Loading");
        console.log("unconfirmedseller command",result.data);
        store.commit("fillUnconfirmedSellerCommand",result.data.commands);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("unconfirmedsellercommandError","erreur de serveur !");
        console.log(exception);
      }
    },
    async getAdminUnConfirmedCommands({ commit }, id) {
      commit("Loading");
      commit("clearErrors");
      try { 
        let result = await axios({
          method: "get",
          url: store.state.urlPrefix + "/api/getAdminUnconfirmedCommands/"+id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
    
        commit("Loading");
        console.log("unconfirmedadmincommand",result.data);
        store.commit("fillUnconfirmedAdminCommand",result.data.commands);
      } catch (exception) {
        commit("Loading");
        commit("catchErrors", "erreur de serveur !");
        sessionStorage.setItem("unconfirmedadmincommandError","erreur de serveur !");
        console.log(exception);
      }
    },



    async updateDeliveryState({ commit }, payload) {
      //commit("Loading");
      commit("clearErrors");
      console.log('this is payload ', payload)
      try {
        let result = await axios({
          method: "post",
          url:
              store.state.urlPrefix + "/api/updateDeliveryState",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {
            delivery_state: payload.delivery_state,
            command_id: payload.command_id
          },
        });
        // console.log('the searched products',result);
       // commit("Loading");
        commit("clearErrors");
        // store.commit("fillSellers", result.data.searched_products);
        // store.state.categories.push(payload);
        // if (result.data.code === 200) {
        //   store.commit("fillpromotedProducts",result.data.promoted_products);
        //   commit("catchSuccess", "Les produits de promotion sont bient ajoutée! ");
        //   sessionStorage.setItem("promotedProductsSuccess","Les produits de promotion sont bient ajoutée!");
        // }
      } catch (exception) {
       // commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        // sessionStorage.setItem("promotedProductsError","erreur de serveur !");
        console.log(exception);
      }
    },
    async updateDeliveryStateSeller({ commit }, payload) {
      //commit("Loading");
      commit("clearErrors");
      console.log('this is payload ', payload)
      try {
        let result = await axios({
          method: "post",
          url:
              store.state.urlPrefix + "/api/updateDeliveryStateSeller",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {
            user_id: payload.user_id,
            command_id: payload.command_id
          },
        });
        // console.log('the searched products',result);
       // commit("Loading");
        commit("clearErrors");
        // store.commit("fillSellers", result.data.searched_products);
        // store.state.categories.push(payload);
        // if (result.data.code === 200) {
        //   store.commit("fillpromotedProducts",result.data.promoted_products);
        //   commit("catchSuccess", "Les produits de promotion sont bient ajoutée! ");
        //   sessionStorage.setItem("promotedProductsSuccess","Les produits de promotion sont bient ajoutée!");
        // }
      } catch (exception) {
       // commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        // sessionStorage.setItem("promotedProductsError","erreur de serveur !");
        console.log(exception);
      }
    },

    async availableBanners({ commit }, payload) {
      //commit("Loading");
      commit("clearErrors");
      // console.log('this is payload ', payload)
      try {
        let result = await axios({
          method: "get",
          url:
              store.state.urlPrefix + "/api/availableBanners",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          // data: {
          //   user_id: payload.user_id,
          //   command_id: payload.command_id
          // },
        });
      
        commit("clearErrors");
        store.commit("fillBanners", result.data.banners);
    
      } catch (exception) {
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },

    async getBannerById({ commit }, id) {
      //commit("Loading");
      commit("clearErrors");
      console.log('this is payload ', id)
      try {
        let result = await axios({
          method: "get",
          url:
              store.state.urlPrefix + "/api/getBannerById/"+id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          // data: {
          //   user_id: payload.user_id,
          //   command_id: payload.command_id
          // },
        });
      
        commit("clearErrors");
        store.commit("fillBanner", result.data.banner);
    
      } catch (exception) {
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        console.log(exception);
      }
    },


    async updateBanner({ commit }, payload) {
      //commit("Loading");
      commit("clearErrors");
      console.log('this is payload ', payload)
      try {
        let result = await axios({
          method: "post",
          url:
              store.state.urlPrefix + "/api/updateBanner/"+payload.bunner_id,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {

            state: payload.state, 
            position : payload.position , 
            link: payload.link,
            category_id: payload.category_id, 
            banner_image: payload.image_url, 

          },
        });
         
        if (result.data.code === 200) {
            
          commit("catchSuccess", "La bannière a été bien modifiée ! ");
                  sessionStorage.setItem("bannerSuccess","La bannière a été bien modifiée ! ");
  
        }
        if (result.data.code === 404) {
         commit("catchErrors", result.data.message);
         sessionStorage.setItem("bannerError",result.data.message);
       }
        // console.log('the searched products',result);
       // commit("Loading");
        commit("clearErrors");
    
      } catch (exception) {
       // commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        // sessionStorage.setItem("promotedProductsError","erreur de serveur !");
        console.log(exception);
      }
    },

    async addBanner({ commit }, payload) {
      //commit("Loading");
      commit("clearErrors");
      console.log('this is payload dyal add', payload)
      try {
      //   let result = await axios({
      //     method: "post",
      //     url:
      //         store.state.urlPrefix + "/api/updateBanner/"+payload.bunner_id,
      //     headers: {
      //       Authorization: "Bearer " + sessionStorage.getItem("token"),
      //     },
      //     data: {

      //       state: payload.state, 
      //       position : payload.position , 
      //       link: payload.link,
      //       category_id: payload.category_id, 
      //       image_url: payload.image_url, 

      //     },
      //   });
         
      //   if (result.data.code === 200) {
            
      //     commit("catchSuccess", "La bannière a été bien modifiée ! ");
      //             sessionStorage.setItem("bannerSuccess","La bannière a été bien modifiée ! ");
  
      //   }
      //   if (result.data.code === 404) {
      //    commit("catchErrors", result.data.message);
      //    sessionStorage.setItem("bannerError",result.data.message);
      //  }
      //   // console.log('the searched products',result);
      //  // commit("Loading");
      //   commit("clearErrors");
    
      } catch (exception) {
       // commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        // sessionStorage.setItem("promotedProductsError","erreur de serveur !");
        console.log(exception);
      }
    },



    
    async updateState({ commit }, payload) {
      //commit("Loading");
      commit("clearErrors");
      // console.log('this is payload ', payload)
      try {
        let result = await axios({
          method: "post",
          url:
              store.state.urlPrefix + "/api/updateState",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: {

            state: payload.state, 
            banner_id : payload.banner_id , 
          
          },
        });
         
        if (result.data.code === 200) {
            
          commit("catchSuccess", "Le status a été bien modifié !");
                  sessionStorage.setItem("stateSuccess","Le status a été bien modifié ! ");
  
        }
        if (result.data.code === 404) {
         commit("catchErrors", result.data.message);
         sessionStorage.setItem("stateError",result.data.message);
       }
        // console.log('the searched products',result);
       // commit("Loading");
        commit("clearErrors");
    
      } catch (exception) {
       // commit("Loading");
        commit("clearSuccess");
        commit("catchErrors", "erreur de serveur !");
        // sessionStorage.setItem("promotedProductsError","erreur de serveur !");
        console.log(exception);
      }
    },
  },});
